import { Button, Card, Col, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import HiredView from "../../components/HiredView";
import Layout from "../../components/Template/Layout";
import api from "../../services/Api";
import "./activityViewPage.scss";

function HiredViewPage() {
  const { hired_id } = useParams();
  const [fetchingData, setFetchingData] = useState(true);
  const [hired, setHired] = useState<any | undefined>();

  useEffect(() => {
    setFetchingData(true);

    api.get("/v1/hireds/" + hired_id).then((response) => {
      if (response.status === 200) {
        const data = response.data;

        setHired(data);
        setFetchingData(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Layout
        pageTitle="Detalhes da Contratada"
        breadcrumb={`Home ⟶ ${hired ? hired.name : "Contratada #" + hired_id}`}
      >
        {!!hired || !fetchingData ? (
          <HiredView hired={hired} />
        ) : (
          <Row className="page-row">
            <Col xs={24} lg={12}>
              <Card>
                <Spin />
              </Card>
            </Col>
          </Row>
        )}
      </Layout>
    </>
  );
}

export default HiredViewPage;
