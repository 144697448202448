import { Card, Col, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import ActivityView from "../../components/ActivityView";
import Layout from "../../components/Template/Layout";
import api from "../../services/Api";
import "./activityViewPage.scss";

function ActivityViewPage() {
  const { activity_id } = useParams();
  const { state } = useLocation();

  const [refreshActivity, setRefreshActivity] = useState<Date>(new Date());
  const [activity, setActivity] = useState<any | undefined>();
  const [projectName, setProjectName] = useState(undefined);
  const breadcrumbActivity = activity
    ? `${activity.wp_code} ⟶ ${activity.name}`
    : `Atividade #${activity_id}`;

  const breadcrumNavigate = !!projectName ? (
    `${projectName} ⟶ CWA ⟶ ${breadcrumbActivity}`
  ) : (
    <>
      <Spin size="small" className="breadcrumb-loading" />{" "}
      {` ⟶ CWA ⟶  ${breadcrumbActivity}`}
    </>
  );

  const breadcrumb = !!state?.breadcrumb ? (
    breadcrumNavigate
  ) : state?.isFromProject ? (
    projectName ? (
      `${projectName} ⟶ CWA ⟶  ${breadcrumbActivity}`
    ) : (
      <>
        <Spin size="small" className="breadcrumb-loading" />{" "}
        {` ⟶ CWA ⟶  ${breadcrumbActivity}`}
      </>
    )
  ) : (
    `Atividades ⟶  ${breadcrumbActivity}`
  );

  useEffect(() => {
    api.get("/v1/activity/" + activity_id).then((response) => {
      if (response?.status === 200) {
        const data = response.data;

        setActivity(data);
      }
    });
  }, [activity_id, refreshActivity]);

  useEffect(() => {
    if (activity) {
      api
        .get("/v1/projects/" + activity?.project_information_id)
        .then((response) => {
          const data = response.data;
          setProjectName(data.name);
        });
    }
  }, [activity]);

  return (
    <>
      <Layout pageTitle="Detalhes de Atividades" breadcrumb={breadcrumb}>
        {activity ? (
          <ActivityView
            hasList={false}
            activity={activity}
            setRefreshActivity={setRefreshActivity}
          />
        ) : (
          <Row className="page-row">
            <Col xs={24} lg={12}>
              <Card>
                <Spin />
              </Card>
            </Col>
          </Row>
        )}
      </Layout>
    </>
  );
}

export default ActivityViewPage;
