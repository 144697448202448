import { useEffect, useState } from "react";
import ContractView from "../../components/ContractView";
import Layout from "../../components/Template/Layout";
import api from "../../services/Api";
import "./contractsPage.scss";

import { Spin } from "antd";
import { useParams } from "react-router-dom";
import CollapseCard from "../../components/Template/Card";

function ContractViewPage() {
  const { contract_id } = useParams();
  const [contract, setContract] = useState();
  const [activities, setActivities] = useState([]);
  const [refresh, setRefresh] = useState(new Date());

  useEffect(() => {
    api.get("/v1/contracts/" + contract_id).then((response) => {
      if (response?.status === 200) {
        const contract = response.data.contract;
        const activities = response.data.activities.map((obj: any) => ({
          ...obj,
          key: obj.id,
        }));

        setContract(contract);
        setActivities(activities);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return (
    <>
      <Layout
        pageTitle="Detalhes do Contrato"
        breadcrumb={`Contratos ⟶ Contrato #${contract_id}`}
      >
        {!!contract ? (
          <ContractView
            contract={contract}
            activities={activities}
            handleRefresh={() => setRefresh(new Date())}
          />
        ) : (
          <CollapseCard title="Dados">
            <Spin />
          </CollapseCard>
        )}
      </Layout>
    </>
  );
}

export default ContractViewPage;
