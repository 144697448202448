import { TableColumnsType } from "antd";
import { statusList } from "../../../../constants/status";
import {
  DataType,
  ExpandedData1Type,
  ExpandedData2Type,
  ExpandedDataType,
} from "../../../../types/cwa";

export const mainTableColumns: TableColumnsType<DataType> = [
  { title: "Código", dataIndex: "cwa_code", key: "cwa_code" },
  { title: "Descrição", dataIndex: "description", key: "description" },
  {
    title: "",
    dataIndex: "actions",
    width: 120,
    key: "actions",
    render: (name, record) => (
      <div
        style={{ display: "flex", justifyContent: "flex-end", paddingRight: 8 }}
      >
        {name}
      </div>
    ),
  },
];

export const mainTableHiredColumns: TableColumnsType<DataType> = [
  { title: "Código", dataIndex: "cwa_code", key: "cwa_code" },
  { title: "Descrição", dataIndex: "description", key: "description" },
];

//

export const table2Columns: TableColumnsType<ExpandedDataType> = [
  { title: "Nome", dataIndex: "name", key: "name" },
  { title: "Descrição", dataIndex: "description", key: "description" },
  {
    title: "Disciplina",
    dataIndex: "discipline_name",
    key: "discipline_name",
  },
  {
    title: "Subdisciplina",
    dataIndex: "sub_discipline_name",
    key: "sub_discipline_name",
  },
  { title: "Status", dataIndex: "status", key: "status_id" },
  {
    title: "",
    dataIndex: "actions",
    width: 155,
    key: "actions",
    render: (name, record) => (
      <div
        style={{ display: "flex", justifyContent: "flex-end", paddingRight: 8 }}
      >
        {name}
      </div>
    ),
  },
];

export const table2HiredColumns: TableColumnsType<ExpandedDataType> = [
  { title: "Nome", dataIndex: "name", key: "name" },
  { title: "Descrição", dataIndex: "description", key: "description" },
  {
    title: "Disciplina",
    dataIndex: "discipline_name",
    key: "discipline_name",
  },
  {
    title: "Subdisciplina",
    dataIndex: "sub_discipline_name",
    key: "sub_discipline_name",
  },
  { title: "Status", dataIndex: "status", key: "status_id" },
];

//

export const table3Columns: TableColumnsType<ExpandedData1Type> = [
  {
    title: "Content",
    dataIndex: "content",
    key: "content",
    width: "25%",
  },
  { title: "Type", dataIndex: "type", key: "type" },
  { title: "Status", dataIndex: "status", key: "status_id" },
];

//

export const table4Columns: TableColumnsType<ExpandedData2Type> = [
  { title: "State", dataIndex: "state", key: "state", width: "30px" },
  { title: "Content", dataIndex: "content", key: "content" },
  {
    title: "Status",
    dataIndex: "status",
    key: "status_id",
    render: (name, record) => (
      <div>
        {statusList[statusList.findIndex((item) => item.value === name)]?.label}
      </div>
    ),
  },
  {
    title: "",
    dataIndex: "actions",
    width: 173,
    key: "actions",
    render: (name, record) => (
      <div
        style={{ display: "flex", justifyContent: "flex-end", paddingRight: 8 }}
      >
        {name}
      </div>
    ),
  },
];

export const table4HiredColumns: TableColumnsType<ExpandedData2Type> = [
  { title: "State", dataIndex: "state", key: "state", width: "30px" },
  { title: "Content", dataIndex: "content", key: "content" },
  {
    title: "Status",
    dataIndex: "status",
    key: "status_id",
    render: (name, record) => (
      <div>
        {statusList[statusList.findIndex((item) => item.value === name)]?.label}
      </div>
    ),
  },
];
