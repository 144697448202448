import { Button, InputNumber, Row, Spin, Switch } from "antd";
import TextArea from "antd/es/input/TextArea";
import { useEffect, useState } from "react";
import api from "../../services/Api";
import { toastAlert } from "../../utils/toastAlert";
import "./contract.scss";

type Props = {
  // start_date: string | undefined;
  // end_date: string | undefined;
  description: string | undefined;
  amount: string | undefined;
  active: boolean;
  hired_id: string | undefined;
  activity_id: string | undefined;
};

function ContractCard({
  contractId,
  afterSubmit,
  onClosePopup,
}: {
  contractId: number;
  afterSubmit?: VoidFunction;
  onClosePopup?: VoidFunction;
}) {
  const isPopup = !!onClosePopup;
  const initialValues = {
    description: undefined,
    amount: undefined,
    active: true,
    hired_id: undefined,
    activity_id: undefined,
  };

  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState<Props>(initialValues);

  useEffect(() => {
    api
      .get(`/v1/contracts/${contractId}`)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data;
          setFormData({
            description: data.description,
            amount: data.amount,
            active: data.active,
            hired_id: data.hired_id,
            activity_id: data.schedule_activity_id,
          });
        }
      })
      .catch((error) => {});
  }, [contractId]);

  const handleEditContract = () => {
    setLoading(true);
    api
      .put(`/v1/contracts/${contractId}`, {
        // start_date: new Date(formData.start_date as string),
        // end_date: new Date(formData.end_date as string),
        description: formData.description,
        amount: formData.amount,
        active: formData.active,
        status: "Assinado",
      })
      .then((response) => {
        toastAlert({
          type: "success",
          message: `O contrato teve suas informações atualizadas.`,
          title: `Contrato atualizado com sucesso `,
        });

        !!afterSubmit && afterSubmit();
        !!onClosePopup && onClosePopup();
      })
      .catch((error) => {
        toastAlert({
          type: "error",
          message: `Verifique sua conexão e tente novamente.`,
          title: `Não foi possível atualizar o contrato `,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return JSON.stringify(initialValues) === JSON.stringify(formData) ? (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "160px",
        width: "100%",
      }}
    >
      <Spin />
    </div>
  ) : (
    <Row
      style={{
        display: "flex",
        gap: 8,
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <TextArea
        placeholder="Descrição"
        value={formData.description}
        onChange={(e) =>
          setFormData((prevState) => ({
            ...prevState,
            description: e.target.value,
          }))
        }
      />

      <InputNumber
        placeholder="Valor"
        prefix="R$"
        value={formData.amount}
        onChange={(e) =>
          setFormData((prevState) => ({
            ...prevState,
            amount: e?.toString(),
          }))
        }
        style={{ width: "100%" }}
      />

      {/* <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <div style={{ display: "flex", gap: 8 }}>
            <label className="date-label">Data de ínicio</label>
            <input
              type="date"
              lang="pt-BR"
              className="date-input"
              value={formData.start_date}
              onChange={(e) =>
                setFormData((prevState) => ({
                  ...prevState,
                  start_date: e.target.value,
                }))
              }
            />
          </div>

          <div style={{ display: "flex", gap: 8 }}>
            <label className="date-label">Data de fim</label>
            <input
              type="date"
              lang="pt-BR"
              className="date-input"
              value={formData.end_date}
              onChange={(e) =>
                setFormData((prevState) => ({
                  ...prevState,
                  end_date: e.target.value,
                }))
              }
            />
          </div>
        </div> */}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: 8,
            width: "100%",
          }}
        >
          <span>Ativo?</span>
          <Switch
            checked={formData.active}
            onChange={(e) =>
              setFormData((prevState) => ({
                ...prevState,
                active: !prevState.active,
              }))
            }
          />
        </div>

        <div
          style={{
            display: "flex",
            gap: 8,
            marginTop: isPopup ? 8 : 0,
          }}
        >
          {!!onClosePopup ? (
            <Button onClick={onClosePopup}>Cancelar</Button>
          ) : null}
          <Button
            loading={loading}
            onClick={handleEditContract}
            className="activity_creation-submit"
            type="primary"
          >
            Editar Contrato
          </Button>
        </div>
      </div>
    </Row>
  );
}

export default ContractCard;
