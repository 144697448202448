import {
  Badge,
  Button,
  Col,
  Row,
  Table,
  TableColumnsType,
  Tabs,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import SearchInput from "../../components/SearchInput";
import Layout from "../../components/Template/Layout";
import api from "../../services/Api";
import "./measurePage.scss";

import { useNavigate } from "react-router-dom";
import CollapseCard from "../../components/Template/Card";
const { Title } = Typography;

interface DataType {
  id: number;
  key: React.Key;
  name: string;
  description: string;
}

function MeasurePage() {
  const [isLoading, setIsLoading] = useState(true);
  const [dataTable, setDataTable] = useState([]);
  const [current, setCurrent] = useState(1);
  const [currentStatus, setCurrentStatus] = useState("1");
  const [total, setTotal] = useState(0);
  const [filteredTotal, setFilteredTotal] = useState(0);
  const navigate = useNavigate();
  const tabs = [
    {
      label: <Badge status="default" text="Pendentes" />,
      key: "1",
      children: <></>,
    },
    // {
    //   label: <Badge status="warning" text="Em andamento" />,
    //   key: "2",
    //   children: <></>,
    // },
    {
      label: <Badge status="success" text="Aprovadas" />,
      key: "4",
      children: <></>,
    },
    {
      label: <Badge status="error" text="Recusadas" />,
      key: "3",
      children: <></>,
    },
  ];

  const [search, setSearch] = useState("");
  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };
  const handleSearch = (search: string) => {
    setIsLoading(true);
    if (search !== "") {
      api
        .post(`/v1/measure/${currentStatus}/search`, {
          search: search,
        })
        .then((r) => {
          const data = r.data;

          const table = data.map((obj: any) => ({
            key: obj.id,
            description: obj.description,
            status: obj.status,
            actions: (
              <>
                <Button
                  type="primary"
                  onClick={() => navigate("/measure/" + obj.id)}
                >
                  Abrir
                </Button>
              </>
            ),
          }));

          setFilteredTotal(r.data.length);
          setDataTable(table);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      fetchItemsByStatus();
    }
  };

  useEffect(() => {
    fetchAllItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchItemsByStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current, currentStatus]);

  const columns: TableColumnsType<DataType> = [
    {
      title: "Descrição",
      dataIndex: "description",
      key: "description",
      render: (name, record) => (
        <div id={`measures-description-${record.key}`}>{name}</div>
      ),
    },
    { title: "", dataIndex: "actions", key: "actions" },
  ];

  const fetchAllItems = async () => {
    setIsLoading(true);
    await api
      .get(`/v1/measure/all`)
      .then((response) => {
        if (response.status === 200) {
          setTotal(response.data.total);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const fetchItemsByStatus = async () => {
    setIsLoading(true);
    await api
      .get(`/v1/measure/status/${currentStatus}?page=${current}`)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.data;

          const table = data.map((obj: any) => ({
            key: obj.id,
            description: `#${obj.id} - ${obj.activity.name}`,
            status: obj.status,
            actions: (
              <>
                <Button
                  type="primary"
                  onClick={() => navigate("/measure/" + obj.id)}
                >
                  Abrir
                </Button>
              </>
            ),
          }));

          setDataTable(table);
          setFilteredTotal(response.data.total);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const onChange = (key: string) => {
    setCurrentStatus(key);
    setCurrent(1);
    setSearch("");
  };

  return (
    <>
      <Layout pageTitle="Lista de Medições" breadcrumb="Home ⟶ Medições">
        <Row gutter={16} className="page-row">
          <Col xs={24} md={6} className="mb-2">
            <CollapseCard title="Total">
              {!!total ? (
                <Title id="measures-total" level={2} className="total-card">
                  {total}
                </Title>
              ) : null}
            </CollapseCard>
          </Col>
          <Col xs={24} md={18} className="mb-2">
            <CollapseCard title="Tabela de Medições">
              <Row>
                <Col span={24} className="mb-2 text-right">
                  <SearchInput
                    type="text"
                    handleChange={handleChangeSearch}
                    value={search}
                    handleSearch={handleSearch}
                    loading={isLoading}
                    placeholder="Busque por uma descrição"
                  />
                </Col>
                <Col span={24}>
                  <Tabs
                    defaultActiveKey="1"
                    type="card"
                    size="small"
                    items={tabs}
                    activeKey={currentStatus}
                    onChange={onChange}
                    className="fluit-tabs"
                  />

                  <Table
                    size="small"
                    dataSource={dataTable}
                    columns={columns}
                    loading={isLoading}
                    pagination={{
                      position: ["bottomCenter"],
                      current: current,
                      pageSize: 30,
                      onChange(page, pageSize) {
                        setCurrent(page);
                      },
                      total: filteredTotal,
                      showSizeChanger: false,
                    }}
                  />
                </Col>
              </Row>
            </CollapseCard>
          </Col>
        </Row>
      </Layout>
    </>
  );
}

export default MeasurePage;
