import { zodResolver } from "@hookform/resolvers/zod";
import { Button, Form } from "antd";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { ContractFormType, ContractSchema } from "../../schemas/contract";
import api from "../../services/Api";
import { toastAlert } from "../../utils/toastAlert";
import { FormProvider } from "../form/FormProvider";
import { InputNumber, Select, Switch, TextArea } from "../form/fields";
import "./contract.scss";
import { SelectActivity } from "./selectActivity";
import { SelectHired } from "./selectHired";

type Hired = {
  name: string;
  email: string;
};

function ContractCard({
  afterSubmit,
  onClosePopup,
  onGoBack,
}: {
  afterSubmit?: (name?: string, email?: string) => void;
  onClosePopup?: VoidFunction;
  onGoBack?: VoidFunction;
}) {
  const { activity_id } = useParams();
  const [form] = Form.useForm();
  const isPopup = !!onClosePopup;
  const [loading, setLoading] = useState(false);
  const [selectedHired, setSelectedHired] = useState<Hired>({
    name: "",
    email: "",
  });

  const methods = useForm<ContractFormType>({
    resolver: zodResolver(ContractSchema),
    defaultValues: { active: true, activity_id },
  });

  const { handleSubmit, watch, trigger, setValue } = methods;
  const values = watch();

  const handleCreateContract = (values: ContractFormType) => {
    setLoading(true);
    api
      .post("/v1/contracts/create", {
        description: values.description,
        active: values.active === undefined ? true : values.active,
        hired_id: values.hired_id,
        status: "Assinado",
        activity_id: isPopup ? values.activity_id : activity_id,
        ...(isPopup && {
          amount: values.amount,
        }),
      })
      .then((response) => {
        toastAlert({
          type: "success",
          message: `Contrato criado com sucesso. Já é possível acessá-lo através da página de contratos`,
          title: "Contrato criado",
        });
        setLoading(false);
        !!afterSubmit && afterSubmit(selectedHired.name, selectedHired.email);
        !!onClosePopup && onClosePopup();
      })
      .catch((error) => {
        setLoading(false);

        toastAlert({
          type: "error",
          message: `Não foi possível criar contrato. Verifique as informações e tente novamente.`,
          title: "Contrato não foi criado",
        });
      });
  };

  return (
    <FormProvider
      methods={methods}
      form={form}
      labelCol={{ xs: 4, lg: isPopup ? 4 : 5, xl: 4, xxl: isPopup ? 4 : 3 }}
      onFinish={handleSubmit(handleCreateContract)}
      style={{
        display: "flex",
        gap: 8,
        flexDirection: "column",
      }}
    >
      <TextArea
        id="contract-description"
        name="description"
        label="Descrição"
        placeholder="Digite a descrição"
      />

      {isPopup ? (
        <InputNumber
          id="contract-amount"
          name="amount"
          type="number"
          label="Valor"
          prefix="R$"
          placeholder="Digite o valor"
          style={{ width: "100%" }}
        />
      ) : null}

      <Select name="hired_id" label="Contratada">
        <SelectHired
          id="contract-hired_id"
          value={values.hired_id}
          setValue={(value: string, data: Hired) => {
            setSelectedHired(data);
            setValue("hired_id", value);
            trigger("hired_id");
          }}
        />
      </Select>

      {isPopup ? (
        <>
          <Select name="activity_id" label="Atividade">
            <SelectActivity
              id="contract-activity_id"
              value={values.activity_id}
              setValue={(value: string) => {
                setValue("activity_id", value);
                trigger("activity_id");
              }}
            />
          </Select>
          <Switch id="contract-active" label="Ativo" name="active" />
        </>
      ) : null}
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: 8,
            marginTop: 0,
          }}
        >
          {!!onClosePopup || !!onGoBack ? (
            <Button onClick={onClosePopup || onGoBack}>Cancelar</Button>
          ) : null}

          <Button
            id="contract-submit"
            htmlType="submit"
            loading={loading}
            disabled={loading}
            className="activity_creation-submit"
            type="primary"
          >
            Criar contrato
          </Button>
        </div>
      </div>
    </FormProvider>
  );
}

export default ContractCard;
