import { useCallback, useEffect, useState } from "react";
import api from "../../config/axios";
import Report from "../../models/Report";
import returnStatusMeasure from "../../utils/returnStatusMeasure";

type Props = {
  page: number;
  refresh?: Date;
  month: number | string;
  year: number | string;
  cwa: number | string;
  project: number | string;
};

export const useFetchReport = ({
  page,
  month,
  refresh,
  year,
  cwa,
  project,
}: Props) => {
  const [report, setReport] = useState([]);
  const [cwas, setCwas] = useState([]);
  const [projects, setProjects] = useState([]);
  const [total, setTotal] = useState(0);
  const [isFetching, setIsFetching] = useState(true);

  const returnReport = useCallback(() => {
    setIsFetching(true);
    setReport([]);
    api
      .get(
        `/v1/report?month=${month}&page=${page}&year=${year}&cwa=${cwa}&project=${project}`
      )
      .then((response) => {
        const data = response.data.data.data;
        setCwas(response.data.cwas);
        setProjects(response.data.projects);

        const table = data.map((obj: Report) => ({
          ...obj,
          key: obj.id,
        }));

        setReport(table);
        setTotal(response.data.total);
      })
      .catch((err) => {})
      .finally(() => {
        setIsFetching(false);
      });
  }, [page, month, year, cwa, project]);

  useEffect(() => {
    returnReport();
  }, [returnReport]);

  return {
    report,
    cwas,
    projects,
    isFetching,
    total,
  };
};
