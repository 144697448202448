import { Card, CardProps } from "antd";
import { useState } from "react";
import { BiSolidDownArrow } from "react-icons/bi";
import "./card.scss";

type Props = CardProps & {
  title: string;
  canCollapse?: boolean;
};

function CollapseCard({
  canCollapse,
  children,
  style,
  className,
  ...others
}: Props) {
  const [collapsed, setCollapsed] = useState(false);
  const [animationHasFinished, setAnimationHasFinished] = useState(true);

  const handleCollapse = () => {
    setCollapsed(!collapsed);
    if (collapsed) {
      setTimeout(() => setAnimationHasFinished(true), 530);
    } else {
      setAnimationHasFinished(false);
    }
  };

  return (
    <Card
      size="small"
      {...others}
      extra={
        canCollapse ? (
          <BiSolidDownArrow
            onClick={handleCollapse}
            style={{
              transition: "all 0.4s ease",
              cursor: "pointer",
              color: "rgb(217 217 217)",
              ...(collapsed && {
                transform: "rotateZ(-180deg)",
              }),
            }}
          />
        ) : (
          <></>
        )
      }
      className={`collapse-card${collapsed ? " collapse-card-collapsed" : ""}${
        !animationHasFinished ? " collapse-card-hide" : ""
      } ${className || ""}`}
      style={{
        ...style,
        transition: "max-height 0.5s ease-in-out",
        maxHeight: collapsed ? "80px" : "100%",
        height: collapsed ? "80px" : "auto",
      }}
    >
      {collapsed ? <></> : children}
    </Card>
  );
}

export default CollapseCard;
