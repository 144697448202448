import { useCallback } from "react";

export function CalculateTotalReport(reports: any[]) {
  const total = useCallback(() => {
    let totalCost = 0;
    reports.forEach((report: any) => (totalCost += +report.cost));
    return totalCost;
  }, [reports]);

  return total();
}
