import { Col, Row, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import { AiOutlineDatabase } from "react-icons/ai";
import {
  FaPencilRuler,
  FaRegFolderOpen,
  FaTasks,
  FaUser,
} from "react-icons/fa";
import { IoMdListBox } from "react-icons/io";

import { useNavigate } from "react-router-dom";
import CollapseCard from "../../components/Template/Card";
import Layout from "../../components/Template/Layout";
import api from "../../services/Api";
import "./homePage.scss";
import { HiOutlineDocumentReport } from "react-icons/hi";

const { Text } = Typography;

function HomePage() {
  const navigate = useNavigate();
  const [roles, setRoles] = useState<string[]>([]);

  useEffect(() => {
    api.get(`/v1/user/roles`).then((response) => {
      if (response.status === 200) {
        const data = response.data;
        setRoles(data.roles);
      }
    });
  }, []);

  return (
    <>
      <Layout pageTitle="Home Page" breadcrumb="Home" cantGoBack>
        <div className="mb-2">
          <CollapseCard title="Acesso Rápido">
            <Row align={"middle"} style={{ justifyContent: "center" }}>
              {roles.length > 0 ? (
                <>
                  <Col
                    id="home-projects"
                    className="dashboard-menu"
                    onClick={() => navigate("/projects")}
                  >
                    <Col className="text-center">
                      <AiOutlineDatabase size={"4em"} />
                    </Col>
                    <Col className="text-center">
                      <Text>Projetos</Text>
                    </Col>
                  </Col>

                  <Col
                    id="home-measures"
                    className="dashboard-menu"
                    onClick={() => navigate("/requisitions")}
                  >
                    <Col className="text-center">
                      <IoMdListBox size={"4em"} />
                    </Col>
                    <Col className="text-center">
                      <Text>Requisições</Text>
                    </Col>
                  </Col>
                  <Col
                    id="home-measures"
                    className="dashboard-menu"
                    onClick={() => navigate("/report")}
                  >
                    <Col className="text-center">
                      <HiOutlineDocumentReport size={"4em"} />
                    </Col>
                    <Col className="text-center">
                      <Text>Relatórios</Text>
                    </Col>
                  </Col>

                  <Col
                    className="dashboard-menu"
                    onClick={() => navigate("/measures")}
                  >
                    <Col className="text-center">
                      <FaPencilRuler size={"4em"} />
                    </Col>
                    <Col className="text-center">
                      <Text>Medições</Text>
                    </Col>
                  </Col>

                  <Col
                    id="home-contracts"
                    className="dashboard-menu"
                    onClick={() => navigate("/contracts")}
                  >
                    <Col className="text-center">
                      <FaRegFolderOpen size={"4em"} />
                    </Col>
                    <Col className="text-center">
                      <Text>Contratos</Text>
                    </Col>
                  </Col>
                  <Col
                    id="home-activities"
                    className="dashboard-menu"
                    onClick={() => navigate("/activities")}
                  >
                    <Col className="text-center">
                      <FaTasks size={"4em"} />
                    </Col>
                    <Col className="text-center">
                      <Text>Atividades</Text>
                    </Col>
                  </Col>
                  {roles.length > 0 &&
                  ["Administrador", "Owner", "Subowner"].some((item) =>
                    roles[0].includes(item)
                  ) ? (
                    <>
                      <Col
                        id="home-hireds"
                        className="dashboard-menu"
                        onClick={() => navigate("/hireds")}
                      >
                        <Col className="text-center">
                          <FaUser size={"4em"} />
                        </Col>
                        <Col className="text-center">
                          <Text>Contratadas</Text>
                        </Col>
                      </Col>
                    </>
                  ) : null}
                </>
              ) : (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    padding: 24,
                  }}
                >
                  <Spin />
                </div>
              )}
            </Row>
          </CollapseCard>
        </div>
      </Layout>
    </>
  );
}

export default HomePage;
