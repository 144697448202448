import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { Button, Form } from "antd";
import { useState } from "react";
import { onlyNumbers } from "../../utils/formatNumber";
import { phoneMask } from "../../utils/masks";
import { Input, TextArea } from "../form/fields";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { useCreateHiredOrEmployee } from "../../hooks/hiredOrEmployee/useCreateHiredOrEmployee";
import {
  HiredEmployeeFormType,
  HiredEmployeeSchema,
} from "../../schemas/hiredEmployee";
import { FormProvider } from "../form/FormProvider";
import "./createEmployee.scss";

type Props = {
  afterSubmit: VoidFunction;
  onClosePopup: VoidFunction;
  type: string;
  hired_id?: number | string;
};

function CreateEmployeeOrHired({
  afterSubmit,
  onClosePopup,
  type,
  hired_id,
}: Props) {
  const title = type === "employees" ? "Colaborador" : "Contratada";
  const [form] = Form.useForm();

  const methods = useForm<HiredEmployeeFormType>({
    resolver: zodResolver(HiredEmployeeSchema),
    defaultValues: { hired_id },
  });

  const { handleSubmit, watch, setValue } = methods;
  const values = watch();

  const [showPassword, setShowPassword] = useState(false);
  const { isFetching, handleFormSubmit } = useCreateHiredOrEmployee({
    formData: {
      ...values,
      phone: onlyNumbers(values.phone || ""),
    },
    afterSubmit,
    onClosePopup,
    type,
  });

  return (
    <FormProvider
      methods={methods}
      form={form}
      labelCol={{ span: 4 }}
      className="form-create-container"
      onFinish={handleSubmit(handleFormSubmit)}
    >
      <Input name="name" label="Nome" placeholder="Digite o nome" />

      <Input
        name="email"
        type="email"
        label="E-mail"
        placeholder="Digite o e-mail"
      />

      <Input
        name="phone"
        type="phone"
        label="Telefone"
        placeholder="Digite o telefone"
        onChange={(event) => {
          setValue("phone", phoneMask(event.target.value || ""));
          form.setFieldValue("phone", phoneMask(event.target.value));
        }}
      />

      <TextArea
        name="description"
        label="Descrição"
        placeholder="Digite a descrição"
      />

      <Input
        name="password"
        type={showPassword ? "text" : "password"}
        label="Senha"
        placeholder="Digite o senha"
        suffix={
          <div
            onClick={() => setShowPassword(!showPassword)}
            style={{ cursor: "pointer" }}
          >
            {showPassword ? (
              <EyeInvisibleOutlined style={{ paddingRight: 8 }} />
            ) : (
              <EyeOutlined style={{ paddingRight: 8 }} />
            )}
          </div>
        }
      />

      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "flex-end",
          gap: 8,
          marginTop: 8,
        }}
      >
        {!!onClosePopup ? (
          <Button onClick={onClosePopup}>Cancelar</Button>
        ) : null}

        <Button
          type="primary"
          htmlType="submit"
          loading={isFetching}
          id="create"
        >
          Criar {title}
        </Button>
      </div>
    </FormProvider>
  );
}

export default CreateEmployeeOrHired;
