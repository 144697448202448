import { Col, Pagination, Row, Table, Typography } from "antd";
import CollapseCard from "../../components/Template/Card";
import Layout from "../../components/Template/Layout";
import "./reportPage.scss";

import { PaginationProps } from "antd/lib/pagination";
import { useState } from "react";
import MonthSelect from "../../components/MonthSelect";
import { useFetchReport } from "../../hooks/report/useFetchReport";
import columns from "./constants/columns";
import YearSelect from "../../components/YearSelect";
import ReportSelect from "./components/ReportSelect";
import { CalculateTotalReport } from "./utils/CalculateTotalReport";
import { formatCurrency } from "../../utils/formatCurrency";
const { Title } = Typography;

export default function ReportPage() {
  const [current, setCurrent] = useState(1);
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");
  const [cwa, setCwa] = useState("");
  const [project, setProject] = useState("");

  const handleChange = (e: string) => {
    setMonth(e);
  };
  const handleChangeYear = (e: string) => {
    setYear(e);
  };
  const handleChangeCwa = (e: string) => {
    setCwa(e);
  };
  const handleChangeProject = (e: string) => {
    setProject(e);
  };
  const onChange: PaginationProps["onChange"] = (page) => {
    setCurrent(page);
  };
  const years = [
    { value: "", label: "Todos" },
    { value: 2023, label: 2023 },
    { value: 2024, label: 2024 },
    { value: 2025, label: 2025 },
  ];
  const { report, isFetching, total, cwas, projects } = useFetchReport({
    page: current,
    month: month,
    year: year,
    project: project,
    cwa: cwa,
  });
  const totalCost = CalculateTotalReport(report);

  return (
    <Layout pageTitle="Relatórios" breadcrumb="Home ⟶ Relatórios">
      <Row gutter={16} className="page-row">
        <Col xs={24} md={6} className="mb-2">
          <CollapseCard title="Total">
            <Title level={2} className="total-card">
              {total ? total : 0}
            </Title>
          </CollapseCard>
        </Col>
        <Col xs={24} md={18}>
          <CollapseCard title="Relatórios" className="card-table-no-padding">
            <Row>
              <div className="selects-container">
                <div className="container-date-select">
                  <div>
                    <ReportSelect
                      label="Selecione o projeto"
                      handleChange={handleChangeProject}
                      values={projects.map((project: any) => {
                        return { value: project.id, label: project.name };
                      })}
                    />
                  </div>
                  <div>
                    <ReportSelect
                      label="Selecione o CWA"
                      handleChange={handleChangeCwa}
                      values={cwas.map((cwa: any) => {
                        return { value: cwa.id, label: cwa.description };
                      })}
                    />
                  </div>
                </div>

                <div className="container-date-select">
                  <div>
                    <MonthSelect
                      handleChange={handleChange}
                      label="Selecione o mês"
                    />
                  </div>
                  <div>
                    <YearSelect
                      label="Selecione o ano"
                      handleChange={handleChangeYear}
                      years={years}
                    />
                  </div>
                </div>
              </div>
              <div className="table-report-container">
                <Table
                  className="table-report"
                  columns={columns}
                  dataSource={report}
                  pagination={false}
                  loading={isFetching}
                  size="small"
                  locale={{ emptyText: "Sem dados" }}
                />
              </div>
              <div className="total-container">
                <span>Total: {formatCurrency(totalCost)}</span>
              </div>

              <Col
                span={24}
                className="text-center mt-4"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "5px",
                }}
              >
                <Pagination
                  current={current}
                  onChange={onChange}
                  total={total}
                  pageSize={30}
                  showSizeChanger={false}
                />
              </Col>
            </Row>
          </CollapseCard>
        </Col>
      </Row>
    </Layout>
  );
}
