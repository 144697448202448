import { Alert, Col, Row, Spin, UploadFile } from "antd";
import { useEffect, useState } from "react";
import CollapseCard from "../../components/Template/Card";
import { useFetchActivityHistory } from "../../hooks/activity/useFetchActivityHistory";
import { useFetchComments } from "../../hooks/activity/useFetchComments";
import { usePostComment } from "../../hooks/activity/usePostComment";
import { useFetchFilesByActivity } from "../../hooks/files/useFetchFilesByActivity";
import { useFetchModelsByActivity } from "../../hooks/files/useFetchModelsByActivity";
import { Activity } from "../../models/Activity";
import api from "../../services/Api";
import AssignContractCard from "../AssignContract/contractCard";
import CommentCard from "../CommentCard";
import { MeasureFileListCard } from "../MeasureFileListCard";
import "./activity.scss";
import {
  ActivityCriteriaCard,
  ActivitySituationCard,
  HistoryCard,
  InformationCard,
  MeasureCard,
  ModelListCard,
  PreviousActivityCard,
  ResponsibleCard,
} from "./cards";

function ActivityView({
  hasList,
  setRefreshActivity,
  isPopup,
  activity,
}: {
  hasList?: boolean;
  isPopup?: boolean;
  activity?: Activity;
  setRefreshActivity: (date: Date) => void;
}) {
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [modelList, setModelList] = useState<UploadFile[]>([]);

  const [showContractCard, setShowContractCard] = useState(true);
  const [criteria, setCriteria] = useState<string | undefined>("");
  const [contractResponsible, setContractResponsible] = useState<
    { name: string; email: string } | undefined
  >();

  const [refreshFileList, setRefreshFileList] = useState(new Date());
  const [refreshModelList, setRefreshModelList] = useState(new Date());

  const [comment, setComment] = useState<string>("");
  const [statusId, setStatusId] = useState<number | undefined>(
    activity?.status_id
  );

  const [roles, setRoles] = useState<string[]>([]);
  const enableSubmit = roles.some((item) =>
    ["Administrador", "Owner"].includes(item)
  );

  const [refreshHistory, setRefreshHistory] = useState<Date>(new Date());
  const [activityIsInitiated, setActivityIsInitiated] = useState<boolean>(
    activity?.status_id !== 1
  );

  const { history } = useFetchActivityHistory({
    activity_id: activity?.id,
    refresh: refreshHistory,
  });

  useEffect(() => {
    api.get(`/v1/user/roles`).then((response) => {
      if (response.status === 200) {
        const data = response.data;
        setRoles(data.roles);
      }
    });
  }, []);

  const { postComment, isFetching, refresh } = usePostComment();

  const { comments } = useFetchComments({
    activity_id: activity?.id,
    refresh,
  });

  const { isLoadingModels } = useFetchModelsByActivity({
    activity_id: activity?.id,
    setModelList,
    refreshModelList,
  });

  const { isLoadingFiles } = useFetchFilesByActivity({
    activity_id: activity?.id,
    setFileList,
    refreshFileList,
  });

  const handleSendComment = async () => {
    const formData = {
      message: comment,
      activity_id: activity?.id,
    };

    if (!isFetching) await postComment({ formData });
  };

  useEffect(() => {
    setComment("");
  }, [isFetching]);

  const contractAfterSubmit = (name?: string, email?: string) => {
    setRefreshActivity(new Date());
    if (name && email) setContractResponsible({ name, email });
    setShowContractCard(false);
  };

  return (
    <>
      <Row gutter={16} className="page-row">
        <Col xs={24} lg={11}>
          <InformationCard
            status_id={statusId}
            showContractCard={showContractCard}
            criteria={criteria}
            isPopup={isPopup}
            activity={activity}
          />

          {activity?.responsible || !!contractResponsible ? (
            <ResponsibleCard
              responsible={activity?.responsible || contractResponsible}
            />
          ) : null}

          <HistoryCard history={history} isPopup={isPopup} />
        </Col>

        <Col xs={24} lg={13} className="mb-2">
          <ActivitySituationCard
            showContractCard={showContractCard}
            isPopup={isPopup}
            activity={activity}
            status_id={statusId}
            setStatus={setStatusId}
            setActivityIsInitiated={setActivityIsInitiated}
            roles={roles}
          />

          {activity &&
          (!!activity.contract || !showContractCard) &&
          !activity.measurement_criteria &&
          activityIsInitiated &&
          !criteria ? (
            <ActivityCriteriaCard
              activity={activity}
              updateCriteria={setCriteria}
              roles={roles}
            />
          ) : null}

          <MeasureCard
            isPopup={isPopup}
            activity={activity}
            showContractCard={showContractCard}
            activityIsInitiated={activityIsInitiated}
            setRefreshFileList={setRefreshFileList}
            fileList={fileList}
            roles={roles}
          />

          {activity && !activity.contract && showContractCard ? (
            <CollapseCard
              canCollapse
              title={`Contrato`}
              className="activity_creation-contract"
            >
              {roles.length === 0 ? (
                <Spin />
              ) : !enableSubmit ? (
                <Alert
                  message="Seu usuário não tem permissão para criar contratos."
                  type="warning"
                  className="w-100"
                />
              ) : (
                <>
                  <div className="activity_creation-contract_message">
                    Atividade não tem contrato, associe um já existente a ela.
                  </div>
                  <AssignContractCard afterSubmit={contractAfterSubmit} />
                </>
              )}
            </CollapseCard>
          ) : null}

          {activity && !!activity.parent_activity ? (
            <PreviousActivityCard activity={activity} />
          ) : null}

          <MeasureFileListCard
            fileList={fileList}
            canCollapse
            isLoading={isLoadingFiles}
            refreshList={() => {
              setRefreshFileList(new Date());
              setRefreshHistory(new Date());
            }}
            roles={roles}
            showButton={
              (activity?.contract || !showContractCard) &&
              activityIsInitiated &&
              activity?.measure?.status_id !== 4
            }
            hideActions={activity?.measure?.status_id === 4}
          />

          {/* <FileListCard
            canCollapse
            cardTitle="Lista de Arquivos"
            fileList={fileList}
            isPopup={isPopup}
            isLoading={isLoadingFiles}
            refreshList={() => {
              setRefreshFileList(new Date());
              setRefreshHistory(new Date());
            }}
          /> */}

          <ModelListCard
            canCollapse
            modelList={modelList}
            isLoading={isLoadingModels}
            refreshList={() => {
              setRefreshModelList(new Date());
              setRefreshHistory(new Date());
            }}
          />

          <CommentCard
            canCollapse
            commentList={comments}
            inputComment={comment}
            setInputComment={setComment}
            handleSendComment={handleSendComment}
            uploading={isFetching}
          />
        </Col>
      </Row>
    </>
  );
}

export default ActivityView;
