import { Form, Select } from "antd";

type Value = {
  label: number | string;
  value: number | string;
};
type Props = {
  handleChange: (e: string) => void;
  label?: string;
  values: Value[];
};
export default function ReportSelect({ handleChange, label, values }: Props) {
  const defaultValues = [{ value: 0, label: "Todos" }];
  return (
    <>
      <div className="container-month-select">
        <Form layout="vertical">
          <Form.Item label={label}>
            <Select
              defaultValue={"Todos"}
              style={{ width: 250 }}
              onChange={handleChange}
              options={[...defaultValues, ...values]}
            />
          </Form.Item>
        </Form>
      </div>
    </>
  );
}
