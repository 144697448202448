import { CloudUploadOutlined } from "@ant-design/icons";
import {
  Button,
  Popconfirm,
  Spin,
  Table,
  Tooltip,
  Upload,
  UploadFile,
} from "antd";
import { ReactNode, useEffect, useState } from "react";
import { FaTrashAlt } from "react-icons/fa";
import { FaCheck } from "react-icons/fa6";
import { IoMdDownload } from "react-icons/io";
import { IoClose } from "react-icons/io5";
import { useParams } from "react-router-dom";
import { useAproveRefuseFile } from "../../hooks/files/useAproveRefuseFile";
import { useDeleteFile } from "../../hooks/files/useDeleteFile";
import { useDownloadFile } from "../../hooks/files/useDownloadFile";
import { useUploadActivityFile } from "../../hooks/files/useUploadActivityFile";
import CollapseCard from "../Template/Card";
import "./fileList.scss";

export function MeasureFileListCard({
  fileList,
  refreshList,
  isLoading,
  canCollapse,
  roles,
  showButton,
  hideActions,
}: {
  fileList: UploadFile[];
  refreshList: VoidFunction;
  isLoading?: boolean;
  canCollapse?: boolean;
  roles: string[];
  showButton: boolean;
  hideActions: boolean;
}) {
  const { activity_id } = useParams();
  const [tableIsLoading, setTableIsLoading] = useState(isLoading);
  const { handleUpload, isUploading } = useUploadActivityFile();

  const dataSource = fileList.map((file: any) => ({
    ...file,
    key: file.id,
    actions: !hideActions ? (
      <TableRowActions
        file={file}
        refreshList={refreshList}
        setTableIsLoading={setTableIsLoading}
        roles={roles}
      />
    ) : (
      <></>
    ),
  }));

  useEffect(() => {
    setTableIsLoading(isLoading);
  }, [isLoading]);

  return (
    <CollapseCard
      canCollapse={canCollapse}
      title="Evidências"
      style={{ marginBottom: 20 }}
    >
      {tableIsLoading && dataSource.length === 0 ? (
        <Spin />
      ) : (
        <>
          <Table
            loading={tableIsLoading}
            size="small"
            columns={[
              { title: "Nome", dataIndex: "show_name" },
              {
                title: "Data de criação",
                dataIndex: "created_at",
                render: (name) => (
                  <>{new Date(name).toLocaleDateString("pt-BR")}</>
                ),
              },
              {
                title: "Qualidade",
                dataIndex: "quality_date",
                render: (name, record) => (
                  <div
                    className={
                      "file-table-list-date " +
                      (!!record.uploadData?.quality_approved_by
                        ? "positive-text-color"
                        : "negative-text-color")
                    }
                  >
                    {!!name ? (
                      <>
                        {!!record.uploadData?.quality_approved_by ? (
                          <FaCheck size={18} />
                        ) : (
                          <IoClose size={20} />
                        )}
                        {new Date(name + "T00:00:00").toLocaleDateString(
                          "pt-BR"
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                ),
              },
              {
                title: "Controle",
                dataIndex: "control_date",
                render: (name, record) => (
                  <div
                    className={
                      "file-table-list-date " +
                      (!!record.uploadData?.control_approved_by
                        ? "positive-text-color"
                        : "negative-text-color")
                    }
                  >
                    {!!name ? (
                      <>
                        {!!record.uploadData?.control_approved_by ? (
                          <FaCheck size={18} />
                        ) : (
                          <IoClose size={20} />
                        )}
                        {new Date(name + "T00:00:00").toLocaleDateString(
                          "pt-BR"
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                ),
              },
              {
                title: "Custo",
                dataIndex: "cost_date",
                render: (name, record) => (
                  <div
                    className={
                      "file-table-list-date " +
                      (!!record.uploadData?.file_approved_by
                        ? "positive-text-color"
                        : "negative-text-color")
                    }
                  >
                    {!!name ? (
                      <>
                        {!!record.uploadData?.file_approved_by ? (
                          <FaCheck size={18} />
                        ) : (
                          <IoClose size={20} />
                        )}
                        {new Date(name + "T00:00:00").toLocaleDateString(
                          "pt-BR"
                        )}
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                ),
              },
              { dataIndex: "actions", width: 90 },
            ]}
            dataSource={dataSource}
            pagination={false}
            className="pb-2"
            rowClassName={(record, index) => {
              return index % 2 === 0 ? 'even-row' : 'odd-row';
            }}
            style={{ overflow: "auto" }}
          />
          <div
            style={{
              display: "flex",
              justifyContent:
                fileList.length > 0 ? "space-between" : "flex-end",
              flexWrap: "wrap",
            }}
          >
            {fileList.length > 0 ? (
              <span>
                * Datas em <span className="positive-text-color">verde</span>{" "}
                indicam aprovação da evidência, enquanto as{" "}
                <span className="negative-text-color">vermelhas</span> indicam
                sua reprovação.
              </span>
            ) : null}
            {showButton ? (
              <Upload
                beforeUpload={(file) => {
                  return false;
                }}
                showUploadList={false}
                className="upload-cloud-btn"
                onChange={(e) =>
                  handleUpload({
                    e,
                    activity_id,
                    setRefreshFileList: refreshList,
                  })
                }
                disabled={isUploading}
                capture
              >
                <Button icon={<CloudUploadOutlined />} loading={isUploading}>
                  {isUploading
                    ? "Carregando arquivo"
                    : "Selecionar arquivos..."}
                </Button>
              </Upload>
            ) : null}
          </div>
        </>
      )}
    </CollapseCard>
  );
}

function TableRowActions({
  file,
  refreshList,
  setTableIsLoading,
  roles,
}: {
  file: any;
  refreshList: VoidFunction;
  setTableIsLoading: React.Dispatch<React.SetStateAction<boolean | undefined>>;
  roles: string[];
}) {
  const { handleDelete, isFetching: isFetchingDelete } = useDeleteFile();
  const {
    handleAprove,
    handleRefuse,
    isFetching: isFetchingAproveRefuse,
  } = useAproveRefuseFile();
  const { handleDownload, isFetching: isFetchingDownload } = useDownloadFile();

  const userCanAprove = () => {
    const { uploadData } = file;
    if (roles.length === 0) return false;
    else if (["Administrador", "Owner"].some((item) => roles[0].includes(item)))
      return true;
    else if (roles[0].includes("Subowner")) {
      let currentStep;

      if (uploadData?.file_approved_by) return false;
      else if (uploadData?.control_approved_by) currentStep = "cost";
      else if (uploadData?.quality_approved_by) currentStep = "control";
      else currentStep = "quality";

      return roles[0].includes("Subowner_" + currentStep);
    } else return false;
  };

  useEffect(() => {
    if (isFetchingDelete || isFetchingAproveRefuse || isFetchingDownload) {
      setTableIsLoading(true);
    } else if (
      !isFetchingDelete &&
      !isFetchingAproveRefuse &&
      !isFetchingDownload
    ) {
      setTableIsLoading(false);
    }
  }, [
    isFetchingDelete,
    isFetchingAproveRefuse,
    isFetchingDownload,
    setTableIsLoading,
  ]);

  const aproveIsDisabled = () =>
    !!file.uploadData.file_approved_by || !userCanAprove();

  const refuseIsDisabled = () => {
    const { uploadData } = file;

    if (!userCanAprove()) return true;

    if (!uploadData.file_approved_by && !!uploadData.file_refused_by) {
      return true;
    } else if (
      !uploadData.control_approved_by &&
      !!uploadData.control_refused_by
    ) {
      return true;
    } else if (
      !uploadData.quality_approved_by &&
      !!uploadData.quality_refused_by
    ) {
      return true;
    } else return !!uploadData.file_approved_by;
  };

  return (
    <div className="file-table-list-actions">
      <PopConfirmIcon
        title="Aprovar"
        description="Tem certeza que deseja aprovar essa evidência?"
        disabled={aproveIsDisabled()}
        onConfirm={() =>
          !aproveIsDisabled() &&
          !isFetchingDelete &&
          handleAprove({ id: file.id, refreshList })
        }
        icon={
          <FaCheck
            size={18}
            className={
              aproveIsDisabled() ? "file-table-list-actions-disabled" : ""
            }
          />
        }
      />
      <PopConfirmIcon
        title="Reprovar"
        description="Tem certeza que deseja reprovar essa evidência?"
        onConfirm={() =>
          !refuseIsDisabled() &&
          !isFetchingDelete &&
          handleRefuse({ id: file.id, refreshList })
        }
        disabled={refuseIsDisabled()}
        icon={
          <IoClose
            size={20}
            className={
              refuseIsDisabled() ? "file-table-list-actions-disabled" : ""
            }
          />
        }
      />
      <Tooltip title="Download">
        <IoMdDownload
          size={20}
          onClick={() => !isFetchingDelete && handleDownload(file.id)}
        />
      </Tooltip>
      <PopConfirmIcon
        title="Deletar"
        description="Tem certeza que deseja deletar essa evidência?"
        onConfirm={(setConfirmIsOpen) =>
          !isFetchingDelete &&
          handleDelete({
            id: file.id,
            refreshList,
            isOpen: () => {
              setConfirmIsOpen(false);
            },
          })
        }
        disabled={isFetchingAproveRefuse}
        icon={<FaTrashAlt size={18} color="red" />}
      />
    </div>
  );
}

function PopConfirmIcon({
  title,
  description,
  onConfirm,
  icon,
  disabled,
}: {
  title: string;
  description: string;
  onConfirm: (
    setConfirmIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  ) => void;
  icon: ReactNode;
  disabled: boolean;
}) {
  const [confirmIsOpen, setConfirmIsOpen] = useState(false);

  return (
    <Popconfirm
      disabled={disabled}
      title={title}
      description={description}
      onConfirm={() => onConfirm(setConfirmIsOpen)}
      onCancel={() => setConfirmIsOpen(false)}
      popupVisible={confirmIsOpen}
      onOpenChange={(open) => setConfirmIsOpen(open)}
      okText="Sim"
      cancelText="Não"
    >
      <Tooltip title={title + " arquivo"}>{icon}</Tooltip>
    </Popconfirm>
  );
}
