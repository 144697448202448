import { Button, Col, Row } from "antd";
import { useEffect, useState } from "react";
import { statusList } from "../../constants/status";
import { useAddContractActivities } from "../../hooks/contracts/useAddContractActivities";
import { Contract } from "../../models/Contract";
import api from "../../services/Api";
import { SelectActivity } from "../HiredView/selectActivity";
import CollapseCard from "../Template/Card";
import "./contract.scss";
import ContractActivitiesModal from "./modal";

interface Props {
  contract: Contract;
  activities: any[];
  handleRefresh: VoidFunction;
}

function ContractView({ contract, activities, handleRefresh }: Props) {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [refreshContractList, setRefreshContractList] = useState(new Date());

  const [newActivityId, setNewActivityId] = useState<any>();
  const [allActivities, setAllActivities] = useState<any[]>([]);

  const { handleAddActivities, refresh, isSubmitting } =
    useAddContractActivities();

  useEffect(() => {
    api.get("/v1/activity/activities-without-contract").then((response) => {
      if (response.status === 200) {
        setAllActivities(
          response.data.data.map((hired: any) => ({
            value: hired.id,
            label: hired.name,
          }))
        );
      }
    });
    handleRefresh()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, refreshContractList]);

  return (
    <>
      <Row gutter={16} className="page-row">
        <Col xs={24} lg={12} xxl={8}>
          <CollapseCard title="Dados" style={{ marginBottom: 20 }}>
            <Row
              style={{
                display: "flex",
                gap: 8,
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 8,
                  width: "100%",
                }}
              >
                <span>{contract?.description}</span>
                <span>Status: {contract?.status}</span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <span>
                  Data de criação:{" "}
                  {new Date(contract?.created_at).toLocaleDateString("pt-BR")}
                </span>
                {!!contract?.end_date ? (
                  <span>
                    Data final:{" "}
                    {new Date(contract?.end_date).toLocaleDateString("pt-BR")}
                  </span>
                ) : null}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <span>
                  Valor:{" "}
                  {(contract &&
                    contract.amount &&
                    `R$ ${contract.amount.toLocaleString("pt-BR", {
                      currency: "BRL",
                      minimumFractionDigits: 2,
                    })}`) ||
                    "Sem Valor"}
                </span>
                <span>{contract?.active ? "Ativo" : "Inativo"}</span>
              </div>
            </Row>
          </CollapseCard>
        </Col>

        <Col xs={24} lg={12}>
          <CollapseCard title="Atividades" className="hired_activities">
            <div className="hired_activities_content">
              {activities.map((activity: any) => (
                <div
                  key={activity.key}
                  className="hired_activities_content_list"
                >
                  <span>{activity?.name}</span>
                  <span style={{ whiteSpace: "nowrap" }}>
                    {
                      statusList[
                        statusList.findIndex(
                          (item) => item.value === activity?.status_id
                        )
                      ]?.label
                    }
                  </span>
                </div>
              ))}
              <div className="hired_activities_content_input-container">
                <SelectActivity
                  newActivityId={newActivityId}
                  setNewActivityId={setNewActivityId}
                  allActivities={allActivities}
                  setAllActivities={setAllActivities}
                />

                <Button
                  type="primary"
                  onClick={() =>
                    handleAddActivities({
                      contract: contract?.id,
                      activities: [newActivityId],
                      handleConfirm: () => setNewActivityId(undefined),
                    })
                  }
                  disabled={!newActivityId || isSubmitting}
                >
                  Atribuir Atividade
                </Button>
                <Button onClick={() => setModalIsOpen(true)}>
                  Atribuir Mais
                </Button>
              </div>
            </div>
          </CollapseCard>
        </Col>
        {modalIsOpen ? (
          <ContractActivitiesModal
            contract={contract?.id}
            allActivities={allActivities}
            isModalOpen={modalIsOpen}
            handleCancel={() => setModalIsOpen(false)}
            handleConfirm={() => {
              setRefreshContractList(new Date());
              setModalIsOpen(false);
            }}
          />
        ) : null}
      </Row>
    </>
  );
}

export default ContractView;
