import { FileFilled, FilePdfFilled } from "@ant-design/icons";
import { Col, Image, Popconfirm, Spin } from "antd";
import { useState } from "react";
import { IoIosClose } from "react-icons/io";
import { useDeleteFile } from "../../hooks/files/useDeleteFile";
import { useDownloadFile } from "../../hooks/files/useDownloadFile";

import { ColSize } from "antd/es/grid";
import "./file.scss";

type CardWidth = {
  xs?: number | string | ColSize | undefined;
  sm?: number | string | ColSize | undefined;
  md?: number | string | ColSize | undefined;
  lg?: number | string | ColSize | undefined;
  xl?: number | string | ColSize | undefined;
  xxl?: number | string | ColSize | undefined;
};

export function FileCard({
  file,
  refreshList,
  cardWidth,
  isModel,
}: {
  file: any;
  refreshList: VoidFunction;
  cardWidth?: CardWidth;
  isModel?: boolean;
}) {
  const { handleDelete, isFetching } = useDeleteFile();
  const { handleDownload } = useDownloadFile();
  const [confirmIsOpen, setConfirmIsOpen] = useState(false);

  const isImg = (extension: string) =>
    [".png", ".jpg", ".jpeg", ".svg", ".gif"].includes(extension);

  return (
    <Col xs={24} sm={12} md={8} xl={6} {...cardWidth}>
      <div
        className={`file-list-item ${
          isFetching ? "file-list-item-disabled" : ""
        }`}
      >
        <Popconfirm
          title="Deletar arquivo"
          description="Tem certeza que deseja deletar esse arquivo?"
          onConfirm={() =>
            !isFetching &&
            handleDelete({
              id: file.id,
              refreshList,
              isModel,
              isOpen: () => {
                setConfirmIsOpen(false);
              },
            })
          }
          onCancel={() => setConfirmIsOpen(false)}
          popupVisible={confirmIsOpen}
          onOpenChange={(open) => setConfirmIsOpen(open)}
          okText="Sim"
          cancelText="Não"
        >
          <IoIosClose
            className={`file-list-item-delete-icon ${
              isFetching ? "file-list-item-delete-icon-disabled" : ""
            }`}
          />
        </Popconfirm>
        <div className="file-list-item-file">
          {isFetching ? (
            <div className="file-list-item-file-isFetching">
              <Spin />
            </div>
          ) : null}
          {isImg(file.extension) ? (
            <Image
              src={file.urlPreview}
              preview={{ mask: "Visualizar" }}
              className={isFetching ? " file-container-disabled" : ""}
              style={{
                minHeight: "50px",
                maxHeight: "80px",
                width: "max-content",
                maxWidth: "100%",
              }}
            />
          ) : file.extension === ".pdf" ? (
            <div
              className={`file-container${
                isFetching ? " file-container-disabled" : ""
              }`}
              onClick={() => !isFetching && handleDownload(file.id)}
            >
              <FilePdfFilled className="file-container-icon pdf-icon" />
            </div>
          ) : (
            <div
              className={`file-container${
                isFetching ? " file-container-disabled" : ""
              }`}
              onClick={() => !isFetching && handleDownload(file.id)}
            >
              <FileFilled className="file-container-icon" />
            </div>
          )}
        </div>

        <span
          key={file.id}
          onClick={() => !isFetching && handleDownload(file.id)}
          className={`file-list-item-text`}
        >
          {file.show_name}
        </span>
      </div>
    </Col>
  );
}
