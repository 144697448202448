import { CloudUploadOutlined } from "@ant-design/icons";
import {
  Alert,
  Avatar,
  Button,
  Col,
  Input,
  Row,
  Select,
  Spin,
  Table,
  TableColumnsType,
  Timeline,
  Upload,
  UploadFile,
} from "antd";
import React, { ReactNode, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { statusList } from "../../constants/status";
import { useUploadActivityFile } from "../../hooks/files/useUploadActivityFile";
import { useSubmitDescription } from "../../hooks/measure/useSubmitDescription";
import api from "../../services/Api";
import { toastAlert } from "../../utils/toastAlert";
import { FileCard } from "../FileCard";
import { ExpandedPreviousActivity } from "../PreviousActivities/expandedRows";
import CollapseCard from "../Template/Card";
import "./activity.scss";
import { formatCurrency } from "../../utils/formatCurrency";

export interface DataType {
  key: React.Key;
  name: string;
}

const { TextArea } = Input;

export function InformationCard({
  status_id,
  showContractCard,
  criteria,
  isPopup,
  activity,
}: {
  status_id?: number | undefined;
  showContractCard?: boolean;
  criteria: string | undefined;
  isPopup?: boolean;
  activity?: any;
}) {
  const currentStatusName =
    statusList[statusList.findIndex((item) => item.value === status_id)]?.label;

  return (
    <CollapseCard
      canCollapse
      title="Informações"
      style={{ marginBottom: isPopup ? 0 : 20 }}
    >
      {" "}
      <InfoData name="Descrição" value={activity?.name} />
      <div className="container-cols-activity">
        <div className="col-infodata">
          <div className="container-info-activity">
            <InfoData name="Tipo" value={activity?.type} />
            <InfoData name="Código" value={activity?.code} />
            <InfoData name="Código WP" value={activity?.wp_code} />
            <InfoData
              name="Subdisciplina"
              value={activity?.sub_discipline && activity?.sub_discipline.name}
            />
          </div>

          <div className="container-info-activity">
            <InfoData name="Horas estimadas" value={activity?.estimate_hours} />
            <InfoData
              name="Porcentagem completa"
              value={activity?.percent_complete}
            />
          </div>
          <div className="container-info-activity">
            <InfoData
              name="Data inicial da linha base"
              value={activity?.baseline_start_date}
            />
            <InfoData
              name="Data final da linha base"
              value={activity?.baseline_completion_date}
            />
          </div>

          {activity && !activity.contract && showContractCard ? (
            <InfoData name="Situação" value={currentStatusName} />
          ) : (
            <></>
          )}
        </div>
        <div className="col-infodata">
          <div className="container-info-activity">
            <InfoData
              name="Medição"
              value={activity?.measure && activity?.measure.description}
            />
            <InfoData
              name="Critérios de medição"
              value={activity?.measurement_criteria || criteria}
            />
            <InfoData name="Flutuação total" value={activity?.total_float} />
          </div>
          <div className="container-info-activity">
            <InfoData name="Início real" value={activity?.actual_start} />
            <InfoData
              name="Conclusão real"
              value={activity?.actual_completion}
            />
          </div>
          <div className="container-info-activity">
            <InfoData
              name="Custo"
              value={
                activity?.cost ? ` ${formatCurrency(activity?.cost)}` : "-"
              }
            />
            <InfoData
              name="Peso"
              value={activity?.percentual ? `${activity?.percentual}%` : "-"}
            />
            <InfoData name="Unidade" value={activity?.unit?.abbreviation} />
            <InfoData name="Quantidade" value={activity?.quantity} />
          </div>
          <div className="container-info-activity">
            <InfoData
              name="Data de criação"
              value={new Date(activity?.created_at).toLocaleDateString(
                "pt-BR",
                {
                  hour: "2-digit",
                  minute: "2-digit",
                }
              )}
            />
            <InfoData
              name="Última atualização"
              value={new Date(activity?.updated_at).toLocaleDateString(
                "pt-BR",
                {
                  hour: "2-digit",
                  minute: "2-digit",
                }
              )}
            />
            <InfoData name="Deletado em" value={activity?.deleted_at} />
          </div>
        </div>
      </div>
    </CollapseCard>
  );
}

export function ResponsibleCard({
  responsible,
}: {
  responsible?: { name: string; email: string; url?: string };
}) {
  const initialletters =
    (responsible?.name?.split(" ")[0]?.charAt(0)?.toUpperCase() || "") +
    (responsible?.name?.split(" ")[1]?.charAt(0)?.toUpperCase() || "");

  return (
    <CollapseCard canCollapse title="Responsável" style={{ marginBottom: 20 }}>
      <div className="activity_creation-profile-card">
        <div className="activity_creation-profile-card_avatar">
          {!!responsible?.url ? (
            <Avatar src={responsible?.url} className="avatarImage" />
          ) : (
            <Avatar className="activity_creation-profile-card_avatar_text">
              {initialletters}
            </Avatar>
          )}
        </div>
        <div className="activity_creation-profile-card_input-list">
          <b>{responsible?.name}</b>
          <span>{responsible?.email}</span>
        </div>
      </div>
    </CollapseCard>
  );
}

export function ActivityCriteriaCard({
  isPopup,
  activity,
  updateCriteria,
  roles,
}: {
  isPopup?: boolean;
  activity?: any;
  updateCriteria: React.Dispatch<React.SetStateAction<string | undefined>>;
  roles: string[];
}) {
  const { activity_id } = useParams();
  const [loading, setLoading] = useState(false);
  const [currentCriteria, setCurrentCriteria] = useState("");

  const enableChangeCriteria =
    roles.some((item) => ["Administrador", "Owner"].includes(item)) ||
    activity?.contract?.hired_id?.toString() ===
      localStorage.getItem("user_hired");

  const handleChangeCriteria = () => {
    if (!currentCriteria) {
      toastAlert({
        type: "warning",
        message: `Preencha o campo de critério para salvar.`,
        title: "Critério não preenchido",
      });
      return;
    }

    setLoading(true);

    api
      .patch(`/v1/activity/criteria/${activity_id}`, {
        measurement_criteria: currentCriteria,
      })
      .then((response) => {
        if (response.status === 200) {
          updateCriteria(currentCriteria);
          toastAlert({
            type: "success",
            message: `O critério da atividade foi alterado com sucesso`,
            title: "Critério atualizado",
          });
        }
      })
      .catch((error) => {
        toastAlert({
          type: "error",
          message: `Não foi possível atualizar o critério da atividade.`,
          title: "Critério não atualizado",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <CollapseCard
      canCollapse
      title="Alterar critérios da medição"
      style={{
        marginBottom: isPopup ? 0 : 20,
      }}
    >
      {roles?.length > 0 ? (
        !enableChangeCriteria ? (
          <Alert
            message="É permitido alterar o critério somente nas atividades que têm seu usuário como responsável."
            type="warning"
          />
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              gap: 8,
            }}
          >
            <TextArea
              id="measurement_criteria"
              name="measurement_criteria"
              placeholder="Digite os critérios de medição"
              value={currentCriteria}
              onChange={(e) => setCurrentCriteria(e.target.value)}
            />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="primary"
                onClick={handleChangeCriteria}
                disabled={loading}
              >
                Salvar
              </Button>
            </div>
          </div>
        )
      ) : (
        <Spin />
      )}
    </CollapseCard>
  );
}

export function ActivitySituationCard({
  showContractCard,
  isPopup,
  activity,
  status_id,
  setStatus,
  setActivityIsInitiated,
  roles,
}: {
  showContractCard: boolean;
  isPopup?: boolean;
  activity?: any;
  status_id: number | undefined;
  setStatus: React.Dispatch<React.SetStateAction<number | undefined>>;
  setActivityIsInitiated: React.Dispatch<React.SetStateAction<boolean>>;
  roles: string[];
}) {
  const { activity_id } = useParams();
  const hasContract = (activity && activity.contract) || !showContractCard;

  const [loading, setLoading] = useState(false);

  const currentStatusName =
    statusList[statusList.findIndex((item) => item.value === status_id)]?.label;

  const enableChangeStatus =
    roles.some((item) => ["Administrador", "Owner"].includes(item)) ||
    activity?.contract?.hired_id?.toString() ===
      localStorage.getItem("user_hired");

  const handleChangeStatus = () => {
    setLoading(true);
    api
      .patch(`/v1/activity/${activity_id}`, {
        status_id,
      })
      .then((response) => {
        if (response.status === 200) {
          setActivityIsInitiated(status_id !== 1);
          toastAlert({
            type: "success",
            message: `Atividade ${currentStatusName.toLowerCase()}`,
            title: "Situação atualizada",
          });
        }
      })
      .catch((error) => {
        toastAlert({
          type: "error",
          message: `Não foi possível atualizar a situação da atividade.`,
          title: "Situação não atualizada",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <CollapseCard
      canCollapse
      title="Alterar situação da atividade"
      style={{
        marginBottom: isPopup ? 0 : 20,
      }}
    >
      {roles?.length > 0 ? (
        !enableChangeStatus ? (
          <Alert
            message="É permitido alterar a situação somente das atividades que têm seu usuário como responsável."
            type="warning"
          />
        ) : hasContract ? (
          <div style={{ display: "flex", gap: 8 }}>
            <Select
              value={status_id}
              placeholder="Status"
              style={{ minWidth: 180 }}
              onChange={(e) => setStatus(e)}
              options={statusList}
            />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="primary"
                onClick={handleChangeStatus}
                disabled={loading}
              >
                Salvar
              </Button>
            </div>
          </div>
        ) : (
          <Alert
            message="Crie um contrato para poder alterar a situação da atividade."
            type="warning"
          />
        )
      ) : (
        <Spin />
      )}
    </CollapseCard>
  );
}

export function HistoryCard({
  history,
  isPopup,
}: {
  history?: any[] | undefined;
  isPopup?: boolean;
}) {
  return (
    <CollapseCard
      canCollapse
      title="Histórico"
      style={{ marginBottom: isPopup ? 0 : 20 }}
    >
      <Row
        style={{
          display: "flex",
          gap: 8,
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Timeline
          mode="left"
          reverse
          items={history?.map((item) => ({
            label: `${new Date(item.created_at).toLocaleDateString(
              "pt-BR"
            )} ${new Date(item.created_at).toLocaleTimeString("pt-BR", {
              hour: "2-digit",
              minute: "2-digit",
            })}`,
            children: item.message,
          }))}
        />
      </Row>
    </CollapseCard>
  );
}

export function MeasureCard({
  isPopup,
  activity,
  showContractCard,
  activityIsInitiated,
  setRefreshFileList,
  fileList,
  roles,
}: {
  isPopup?: boolean;
  activity?: any;
  showContractCard: boolean;
  activityIsInitiated: boolean;
  setRefreshFileList: React.Dispatch<React.SetStateAction<Date>>;
  fileList: UploadFile<any>[];
  roles: string[];
}) {
  const navigate = useNavigate();

  const [description, setDescription] = useState("");
  const [showErrors, setShowErrors] = useState(false);

  const enableSubmit =
    roles.some((item) => ["Administrador", "Owner"].includes(item)) ||
    activity?.contract?.hired_id?.toString() ===
      localStorage.getItem("user_hired");

  const hasContract = (activity && activity.contract) || !showContractCard;
  const hasRefusedMeasure =
    !!activity.measure && activity.measure.status_id === 3;

  const { handleSubmitAfterRefused, isFetching, handleSubmitDescription } =
    useSubmitDescription();

  return (
    <CollapseCard
      canCollapse
      title="Medição"
      style={{ marginBottom: isPopup ? 0 : 20, flex: 1 }}
    >
      <Row
        style={{
          display: "flex",
          gap: activity && !!activity.measure ? 18 : 8,
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        {activity && roles?.length > 0 ? (
          !!activity.measure && !hasRefusedMeasure ? (
            <>
              <span>Atividade já tem medição</span>
              <div className="activity_creation-actions">
                <Button
                  onClick={() => navigate("/measure/" + activity.measure?.id)}
                  className="activity_creation-submit"
                  type="primary"
                >
                  Acompanhar status da medição
                </Button>
              </div>
            </>
          ) : hasContract && activityIsInitiated ? (
            <>
              {!enableSubmit ? (
                <Alert
                  message="É permitido submeter somente as atividades que têm seu usuário como responsável."
                  type="warning"
                  className="w-100"
                />
              ) : null}
              <TextArea
                placeholder={`Descrição da ${
                  hasRefusedMeasure ? "atualização da" : ""
                } medição`}
                rows={2}
                onChange={(event) => {
                  setDescription(event.target.value);
                }}
                disabled={!enableSubmit}
                style={{ marginTop: "8px" }}
              />

              <div className="activity_creation-actions">
                <Button
                  onClick={() =>
                    hasRefusedMeasure
                      ? handleSubmitAfterRefused({
                          activity,
                          description,
                          fileList,
                          setShowErrors,
                        })
                      : handleSubmitDescription({
                          activity,
                          description,
                          fileList,
                          setShowErrors,
                        })
                  }
                  className="activity_creation-submit"
                  disabled={isFetching || !enableSubmit || fileList.length === 0}
                  loading={isFetching}
                  type="primary"
                >
                  Submeter {hasRefusedMeasure ? "novamente" : ""} para medição
                </Button>
              </div>
            </>
          ) : (
            <Alert
              message={
                !enableSubmit
                  ? "É permitido submeter somente as atividades que têm seu usuário como responsável."
                  : !hasContract
                  ? "Crie um contrato para poder submeter para medição."
                  : "Atividades não iniciadas não podem ser submetidas para medição."
              }
              type="warning"
              className="w-100"
            />
          )
        ) : (
          <Spin />
        )}
      </Row>
    </CollapseCard>
  );
}

export function PreviousActivityCard({ activity }: { activity?: any }) {
  const navigate = useNavigate();
  const dataSource = activity.parent_activity;
  const columns: TableColumnsType<DataType> = [
    { title: "Nome", dataIndex: "name", key: "name" },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      render: (data) => <div className="align-right">{data}</div>,
    },
  ];

  const expandedRowRender = (data: any): ReactNode => {
    return (
      <ExpandedPreviousActivity
        id={data.id}
        currentActivity={dataSource.parent_activity}
      />
    );
  };

  return (
    <CollapseCard
      canCollapse
      title={`Atividade predecessora`}
      style={{ marginBottom: 20 }}
    >
      <Table
        columns={columns}
        expandable={
          !!dataSource?.parent_activity ? { expandedRowRender } : undefined
        }
        dataSource={[
          {
            ...dataSource,
            actions: (
              <>
                <Button
                  type="primary"
                  onClick={() => navigate("/activity/" + dataSource.id)}
                >
                  Ir para atividade
                </Button>
              </>
            ),
          },
        ]}
        pagination={false}
        size="small"
      />
    </CollapseCard>
  );
}

export function ModelListCard({
  modelList,
  isLoading,
  canCollapse,
  refreshList,
}: {
  modelList: UploadFile[];
  isLoading?: boolean;
  canCollapse?: boolean;
  refreshList: VoidFunction;
}) {
  const { activity_id } = useParams();
  const { handleUpload, isUploading } = useUploadActivityFile();

  return (
    <CollapseCard
      canCollapse={canCollapse}
      title="Modelos"
      style={{ marginBottom: 20 }}
    >
      <Row gutter={16} wrap>
        {isLoading ? (
          <Spin />
        ) : (
          <>
            {modelList && modelList.length > 0 ? (
              modelList?.map((item: any) => (
                <FileCard
                  key={item.id}
                  file={item}
                  refreshList={refreshList}
                  isModel
                />
              ))
            ) : (
              <div className="pl-1">
                Atividade não tem modelos, clique no botão abaixo para fazer
                upload.
              </div>
            )}
            <Col xs={24}>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Upload
                  beforeUpload={(file) => false}
                  showUploadList={false}
                  className="upload-cloud-btn"
                  onChange={(e) =>
                    handleUpload({
                      e,
                      activity_id,
                      setRefreshFileList: refreshList,
                      relation: "activityModels",
                    })
                  }
                  disabled={isUploading}
                  capture
                >
                  <Button icon={<CloudUploadOutlined />} loading={isUploading}>
                    {isUploading ? "Carregando modelo" : "Selecionar modelo..."}
                  </Button>
                </Upload>
              </div>
            </Col>
          </>
        )}
      </Row>
    </CollapseCard>
  );
}

function InfoData({ name, value }: { name: string; value?: string | number }) {
  return (
    <Col span={24} className="mb-05">
      <b>{name}: </b>
      {value || "-"}
    </Col>
  );
}
