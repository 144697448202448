import { useState } from "react";
import api from "../../config/axios";
import { toastAlert } from "../../utils/toastAlert";

type Props = {
  id: number;
  isModel?: boolean;
  isOpen: () => void;
  refreshList: VoidFunction;
};
export const useDeleteFile = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [refresh, setRefresh] = useState<Date>();

  const handleDelete = async ({ id, isOpen, refreshList, isModel }: Props) => {
    setIsFetching(true);

    await api
      .delete(`/v1/files/${id}?isModel=${!!isModel}`)
      .then((response) => {
        refreshList();
        setRefresh(new Date());
        toastAlert({
          type: "success",
          message: `Arquivo foi removido da atividade com sucesso.`,
          title: "Deletado com sucesso",
        });
      })
      .catch((error) => {
        toastAlert({
          type: "error",
          message:
            error?.response?.data?.message ||
            `Verifique sua conexão com a internet para tentar novamente.`,
          title: "Erro ao deletar",
        });
      })
      .finally(() => {
        setIsFetching(false);
        isOpen();
      });
  };
  return { handleDelete, isFetching, refresh };
};
