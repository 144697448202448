import { useEffect, useState } from "react";
import api from "../../services/Api";
import SelectInputSearch from "../SelectInputSearch";
import "./contract.scss";

type Hired = {
  name: string;
  email: string;
};

let getContractSearch: AbortController;

export function SelectContract({
  value,
  setValue,
}: {
  value: any;
  setValue: (value: string, data: Hired) => void;
}) {
  const [search, setSearch] = useState("");
  const [loadingContracts, setLoadingContracts] = useState(true);
  const [contractList, setContractList] = useState<any[] | undefined>();

  useEffect(() => {
    getContractsData();
  }, []);

  const handleSearch = () => {
    setLoadingContracts(true);

    if (getContractSearch) getContractSearch.abort();
    getContractSearch = new AbortController();

    if (search !== "") {
      api
        .post(
          "/v1/contracts/search",
          { search },
          { signal: getContractSearch?.signal }
        )
        .then((r) => {
          if (r.status === 200) {
            setContractList(
              r.data.map((contract: any) => ({
                ...contract,
                value: contract.id,
                label: contract.description,
              }))
            );
          }
        })
        .finally(() => {
          setLoadingContracts(false);
        })
        .catch(() => {});
    } else {
      getContractsData();
    }
  };

  async function getContractsData() {
    setLoadingContracts(true);

    await api
      .get(`/v1/contracts/all`)
      .then((response) => {
        if (response.status === 200) {
          setContractList(
            response.data.data.map((contract: any) => ({
              ...contract,
              value: contract.id,
              label: contract.description,
            }))
          );
        }
      })
      .catch((error) => {})
      .finally(() => {
        setLoadingContracts(false);
      });
  }

  const onChange = (value: any, option: any) => {
    setValue(value, { name: option?.hired_name, email: option?.hired_email });
  };

  return (
    <SelectInputSearch
      placeholder="Selecione um contrato"
      onChange={onChange}
      value={value}
      onSearch={(value: string) => setSearch(value)}
      search={search}
      options={contractList || []}
      getData={handleSearch}
      loading={loadingContracts}
    />
  );
}
