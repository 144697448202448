import { UploadFile } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../config/axios";
import { Activity } from "../../models/Activity";
import { toastAlert } from "../../utils/toastAlert";

type Props = {
  activity: Activity;
  description: string;
  fileList: UploadFile<any>[];
  setShowErrors: React.Dispatch<React.SetStateAction<boolean>>;
};

export const useSubmitDescription = () => {
  const [isFetching, setIsFetching] = useState(false);
  const navigate = useNavigate();

  const validateSubmit = ({
    description,
    fileList,
    setShowErrors,
  }: {
    description: string | null;
    fileList: UploadFile<any>[];
    setShowErrors: React.Dispatch<React.SetStateAction<boolean>>;
  }) => {
    // if (!description && fileList.length === 0) {
    //   toastAlert({
    //     type: "warning",
    //     message:
    //       "Preencha a descrição e mande uma evidência para poder submeter para medição.",
    //     title: "Espere!",
    //   });

    //   setShowErrors(true);
    // } else {
      // if (!description) {
      //   toastAlert({
      //     type: "warning",
      //     message: "Preencha a descrição da medição para poder submetê-la.",
      //     title: "Espere!",
      //   });

      //   setShowErrors(true);
      // }
      if (fileList.length === 0) {
        toastAlert({
          type: "warning",
          message: "Mande uma evidência para poder submeter para medição.",
          title: "Espere!",
        });

        setShowErrors(true);
      }
    // }
    return /*!description ||*/ fileList.length === 0;
  };

  const handleSubmitDescription = async ({
    activity,
    description,
    fileList,
    setShowErrors,
  }: Props) => {
    if (validateSubmit({ description, fileList, setShowErrors })) return;

    const formData = {
      type: activity.type,
      status: "1",
      description: description,
      activity_id: activity.id,
      hired_id: activity.contract?.hired_id,
      tenant_id: activity.type,
      activity,
    };
    setIsFetching(true);

    await api
      .post("/v1/measure/create", formData)
      .then((response) => {
        toastAlert({
          type: "success",
          message:
            "Atividade foi submetida para aprovação pelo time de qualidade, você será notificado quando o status for atualizado.",
          title: "Atividade submetida",
        });

        navigate("/measure/" + response.data.id);
      })
      .catch((error) => {
        toastAlert({
          type: "error",
          message: `${
            error?.response?.data?.message ||
            "Não foi possível submeter para medição. Verifique sua conexão e tente novamente."
          }`,
          title: "Atividade não submetida",
        });
      })
      .finally(() => setIsFetching(false));
  };

  const handleSubmitAfterRefused = async ({
    activity,
    description,
    fileList,
    setShowErrors,
  }: Props) => {
    if (validateSubmit({ description, fileList, setShowErrors })) return;

    const formData = {
      status: 1,
      description: description,
    };
    setIsFetching(true);

    await api
      .post(`/v1/measure/${activity.measure.id}/create-after-refused`, formData)
      .then((response) => {
        toastAlert({
          type: "success",
          message:
            "Atividade foi submetida para aprovação pelo time de qualidade, você será notificado quando o status for atualizado",
          title: "Atividade submetida",
        });

        navigate("/measure/" + response.data.id);
      })
      .catch((error) => {
        toastAlert({
          type: "error",
          message: `${
            error?.response?.data?.message ||
            "Não foi possível submeter para medição. Verifique sua conexão e tente novamente."
          }`,
          title: "Atividade não submetida",
        });
      });

    setIsFetching(false);
  };

  return { handleSubmitAfterRefused, isFetching, handleSubmitDescription };
};
