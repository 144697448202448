import { Button, Col, Grid, Row, Spin, Table, TableColumnsType } from "antd";

import { PaginationProps } from "antd/lib/pagination";
import { Key, ReactNode, useEffect, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { statusList } from "../../constants/status";
import { Activity } from "../../models/Activity";
import api from "../../services/Api";
import "./cwpView.scss";
import { ExpandedTable } from "./expandTable";

const { useBreakpoint } = Grid;

export type TableType = {
  ipws: { data: any; isLoading: boolean; isLoadingId: any; total: number };
  activities: {
    data: any;
    isLoading: boolean;
    isLoadingId: any;
    total: number;
  };
};

const iwpColumns: TableColumnsType<any> = [
  { title: "IWP", dataIndex: "description", key: "description" },
  { title: "", dataIndex: "actions", width: 110, key: "actions" },
];

const iwpColumnsXxl: TableColumnsType<any> = [
  { title: "IWP", dataIndex: "name", key: "name" },
  { title: "Descrição", dataIndex: "description", key: "description" },
  { title: "", dataIndex: "actions", width: 110, key: "actions" },
];

const activityColumns: TableColumnsType<any> = [
  { title: "Atividades do CWP", dataIndex: "atividades", key: "atividades" },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (name, record) => (
      <div>
        {statusList[statusList.findIndex((item) => item.value === name)]?.label}
      </div>
    ),
  },
  { title: "Responsável", dataIndex: "responsavel", key: "responsavel" },
  { title: "", dataIndex: "actions", width: 110, key: "actions" },
];

function CwpView({
  cwpId,
  initialActivities,
  setTotalIwps,
}: {
  cwpId: number;
  initialActivities: Activity[];
  setTotalIwps: (n: number) => void;
}) {
  const navigate = useNavigate();
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(0);
  const { xxl } = useBreakpoint();
  const [currentKeys, setCurrentKeys] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tables, setTables] = useState<TableType>({
    ipws: { data: [], isLoading: true, isLoadingId: null, total: 0 },
    activities: { data: [], isLoading: true, isLoadingId: null, total: 0 },
  });

  useEffect(() => {
    fetchIwps();
  }, [current]);

  const fetchIwps = async () => {
    setIsLoading(true);

    await api
      .get(`v1/iwps/cwp/${cwpId}?page=${current}`)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.data;
          const table = data.map((obj: any) => ({
            ...obj,
            key: obj.id,
          }));

          setTables((prevState) => ({
            ...prevState,
            ipws: {
              ...prevState.ipws,
              data: table,
              total: response.data.total,
            },
          }));
          setTotal(response.data.total);

          setTotalIwps(response.data.total);
        }
      })
      .finally(() => setIsLoading(false));
  };

  const expandedRowRender = (data: any): ReactNode => (
    <ExpandedTable
      table={tables.activities}
      record={data}
      setExpandedData={async ({
        key,
        page,
      }: {
        key: Key;
        page?: number | undefined;
      }) => await setExpandedData({ key, page, setTables, navigate })}
    />
  );

  const onChange: PaginationProps["onChange"] = (page) => {
    setCurrent(page);
  };

  return (
    <Row gutter={16}>
      {isLoading ? (
        <Spin />
      ) : (
        <>
          <Col span={24} className="mb-2">
            <div style={{ overflow: "auto" }}>
              <Table
                className="table-default-style"
                dataSource={tables.ipws.data}
                columns={xxl ? iwpColumnsXxl : iwpColumns}
                expandable={{
                  expandedRowRender,
                  onExpandedRowsChange: (keys) => {
                    if (currentKeys.length < keys.length) {
                      setExpandedData({
                        key: keys[keys.length - 1],
                        page: 1,
                        setTables,
                        navigate,
                      });
                    }
                    setCurrentKeys([...keys]);
                  },
                }}
                pagination={{
                  current,
                  onChange,
                  total,
                  pageSize: 30,
                  showSizeChanger: false,
                }}
                style={{ minWidth: "600px" }}
              />
            </div>
          </Col>
          <Col span={24}>
            <div style={{ overflow: "auto" }}>
              <Table
                className="table-default-style"
                dataSource={initialActivities}
                columns={activityColumns}
                style={{ minWidth: "600px" }}
              />
            </div>
          </Col>
        </>
      )}
    </Row>
  );
}

export default CwpView;

const setExpandedData = async ({
  key,
  page,
  navigate,
  setTables,
}: {
  key: Key;
  page?: number | undefined;
  navigate: NavigateFunction;
  setTables: React.Dispatch<React.SetStateAction<TableType>>;
}) => {
  if (!key) return false;

  setTables((prevState) => ({
    ...prevState,
    activities: { ...prevState.activities, isLoading: true, isLoadingId: key },
  }));
  await api
    .get(`/v1/activity/iwp/${key}?page=` + (page || 1))
    .then((response) => {
      if (response.status === 200) {
        const data = response.data?.data?.map((obj: any) => ({
          ...obj,
          key: obj.id,
          atividades: obj.name,
          responsavel: obj.responsible_name || "-",
          status: obj.status_id,
          actions: (
            <Button
              type="primary"
              onClick={() => navigate("/activity/" + obj.id)}
            >
              Abrir
            </Button>
          ),
        }));
        setTables((prevState) => ({
          ...prevState,
          activities: {
            ...prevState.activities,
            data: { ...prevState.activities.data, [key]: data || [] },
            total: response.data.total,
          },
        }));
      }

      setTables((prevState) => ({
        ...prevState,
        activities: {
          ...prevState.activities,
          isLoading: false,
          isLoadingId: null,
        },
      }));
    });
};
