import {
  Badge,
  Button,
  Col,
  Row,
  Spin,
  Table,
  TableColumnsType,
  Tabs,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import Layout from "../../components/Template/Layout";
import api from "../../services/Api";
import "./contractsPage.scss";
import ContractModal from "./modal";

import { useNavigate } from "react-router-dom";
import SearchInput from "../../components/SearchInput";
import CollapseCard from "../../components/Template/Card";
const { Title } = Typography;

interface DataType {
  id: number;
  key: React.Key;
  name: string;
  description: string;
}

type ModalDataType = {
  isOpen: boolean;
  type: "create" | "edit";
  id: number | undefined;
};

function ContractsPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [dataTable, setDataTable] = useState([]);
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(0);
  const [filteredTotal, setFilteredTotal] = useState(0);
  const [currentStatus, setCurrentStatus] = useState("true");
  const [refreshTableData, setRefreshTableData] = useState(new Date());
  const [modalData, setModalData] = useState<ModalDataType>({
    isOpen: false,
    type: "create",
    id: undefined,
  });
  const [roles, setRoles] = useState<string[]>([]);
  const tabs = [
    {
      label: <Badge status="success" text="Ativos" />,
      key: "true",
      children: <></>,
    },
    {
      label: <Badge status="error" text="Inativos" />,
      key: "false",
      children: <></>,
    },
  ];

  useEffect(() => {
    api.get(`/v1/user/roles`).then((response) => {
      if (response.status === 200) {
        const data = response.data;
        setRoles(data.roles);
      }
    });
  }, []);

  const columns: TableColumnsType<DataType> = [
    {
      title: "Descrição",
      dataIndex: "description",
      key: "description",
      render: (name, record) => (
        <div id={`contracts-description-${record.id}`}>{name}</div>
      ),
    },
    { title: "", dataIndex: "actions", key: "actions" },
  ];

  const fetchAllItems = async () => {
    api.get(`/v1/contracts/all?page=${current}`).then((response) => {
      if (response.status === 200) {
        setTotal(response.data.total);
      }
    });
  };

  const fetchItemsByActive = async () => {
    setIsLoading(true);

    api
      .get(`/v1/contracts/active/${currentStatus}?page=${current}`)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.data;
          const table = data.map((obj: any) => ({
            ...obj,
            key: obj.id,
            actions: (
              <Actions
                obj={obj}
                roles={roles}
                setRefreshTableData={setRefreshTableData}
                handleOpenModal={handleOpenModal}
              />
            ),
          }));

          setDataTable(table);
          setFilteredTotal(response.data.total);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const [search, setSearch] = useState("");
  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };
  const handleSearch = (search: string) => {
    setIsLoading(true);

    if (search !== "") {
      api
        .post(`/v1/contracts/${currentStatus}/search`, {
          search: search,
        })
        .then((r) => {
          const data = r.data;

          const table = data.map((obj: any) => ({
            ...obj,
            key: obj.id,
            actions: (
              <Actions
                obj={obj}
                roles={roles}
                setRefreshTableData={setRefreshTableData}
                handleOpenModal={handleOpenModal}
              />
            ),
          }));
          setFilteredTotal(r.data.length);
          setDataTable(table);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      fetchAllItems();
    }
  };

  useEffect(() => {
    fetchAllItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTableData, roles]);

  useEffect(() => {
    fetchItemsByActive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshTableData, current, roles, currentStatus]);

  const handleOpenModal = ({
    id,
    type,
  }: {
    id?: number;
    type: "create" | "edit";
  }) => {
    setModalData({ isOpen: true, type, id });
  };

  const onChange = (key: string) => {
    setCurrentStatus(key);
    setCurrent(1);
    setSearch("");
  };

  return (
    <>
      <Layout
        pageTitle="Lista de Contratos"
        breadcrumb="Home ⟶ Contratos"
        actions={
          <>
            {roles.some((item) => ["Administrador", "Owner"].includes(item)) ? (
              <Button
                id="contract-create"
                className="button-secondary"
                onClick={() => handleOpenModal({ type: "create" })}
                disabled={isLoading}
              >
                Criar Contrato
              </Button>
            ) : null}
          </>
        }
      >
        <Row gutter={16} className="page-row">
          <Col xs={24} md={6} className="mb-2">
            <CollapseCard title="Total">
              {!!total ? (
                <Title id="contracts-total" level={2} className="total-card">
                  {total}
                </Title>
              ) : null}
            </CollapseCard>
          </Col>
          <Col xs={24} md={18}>
            <CollapseCard title="Tabela de Contratos">
              <Row>
                <Col span={24}>
                  <Col span={24} className="mb-2 text-right">
                    <SearchInput
                      type="text"
                      handleChange={handleChangeSearch}
                      value={search}
                      handleSearch={handleSearch}
                      loading={isLoading}
                      placeholder="Busque por nome"
                    />
                  </Col>
                  <Tabs
                    defaultActiveKey="1"
                    type="card"
                    size="small"
                    items={tabs}
                    activeKey={currentStatus}
                    onChange={onChange}
                    className="fluit-tabs"
                  />
                  {roles.length === 0 ? (
                    <Spin />
                  ) : (
                    <Table
                      size="small"
                      dataSource={dataTable}
                      columns={columns}
                      loading={isLoading}
                      pagination={{
                        position: ["bottomCenter"],
                        current: current,
                        pageSize: 30,
                        onChange(page, pageSize) {
                          setCurrent(page);
                        },
                        total: filteredTotal,
                        showSizeChanger: false,
                      }}
                    />
                  )}
                  {/* <Table
                    className="table-projects"
                    columns={columns}
                    dataSource={dataTable}
                    pagination={false}
                    loading={isLoading}
                    locale={{ emptyText: "Sem dados" }}
                  /> */}
                </Col>
              </Row>
            </CollapseCard>
            {modalData.isOpen ? (
              <ContractModal
                contractId={modalData.id}
                isModalOpen={modalData.isOpen}
                isCreate={modalData.type === "create"}
                handleCancel={() =>
                  setModalData((prevState) => ({ ...prevState, isOpen: false }))
                }
                afterSubmit={() => setRefreshTableData(new Date())}
              />
            ) : null}
          </Col>
        </Row>
      </Layout>
    </>
  );
}

function Actions({
  obj,
  roles,
  setRefreshTableData,
  handleOpenModal,
}: {
  roles: any[];
  obj: any;
  setRefreshTableData: React.Dispatch<React.SetStateAction<Date>>;
  handleOpenModal: ({
    id,
    type,
  }: {
    id?: number | undefined;
    type: "create" | "edit";
  }) => void;
}) {
  const navigate = useNavigate();

  return (
    <div style={{ display: "flex", gap: 8 }}>
      <Button
        type="primary"
        onClick={() => navigate("/contract/" + obj.id)}
        className="button-open-cell-contract"
      >
        Abrir
      </Button>
      {/* {roles.some((item) => ["Administrador", "Owner"].includes(item)) ? (
        <>
          <Button onClick={() => handleOpenModal({ id: obj.id, type: "edit" })}>
            Editar
          </Button>
          <Popconfirm
            title="Deletar contrato"
            description="Tem certeza que deseja deletar esse contrato?"
            onConfirm={() => handleDelete(obj.id)}
            onCancel={() => {}}
            okText="Sim"
            cancelText="Não"
          >
            <Button danger>Deletar</Button>
          </Popconfirm>
        </>
      ) : null} */}
    </div>
  );
}

export default ContractsPage;
