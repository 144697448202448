import { Form, Select } from "antd";

type Value = {
  label: number | string;
  value: number | string;
};
type Props = {
  handleChange: (e: string) => void;
  label?: string;
  years?: Value[];
};
export default function YearSelect({ handleChange, label, years }: Props) {
  return (
    <>
      <div className="container-month-select">
        <Form layout="vertical">
          <Form.Item label={label}>
            <Select
              defaultValue={""}
              style={{ width: 150 }}
              onChange={handleChange}
              options={years}
            />
          </Form.Item>
        </Form>
      </div>
    </>
  );
}
