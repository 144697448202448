import {
  Button,
  Col,
  Pagination,
  PaginationProps,
  Row,
  Table,
  TableColumnsType,
  Typography,
} from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SearchInput from "../../components/SearchInput";
import CollapseCard from "../../components/Template/Card";
import Layout from "../../components/Template/Layout";
import api from "../../services/Api";
import "./activityPage.scss";
const { Title } = Typography;

interface DataType {
  id: number;
  key: React.Key;
  content: string;
  children?: DataType[];
}

function ActivityPage() {
  const [isLoading, setIsLoading] = useState(true);
  const [current, setCurrent] = useState(1);
  const [total, setTotal] = useState(0);
  const [dataTable, setDataTable] = useState([]);
  const [pageSize, setPageSize] = useState(30);
  const navigate = useNavigate();
  const [roles, setRoles] = useState<string[]>([]);

  async function getData() {
    setIsLoading(true);
    await api
      .get(`/v1/activity/all?page=${current}`)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.data;

          const table = data.map((obj: any) => ({
            ...obj,
            key: obj.id,
            actions: (
              <>
                <Button
                  type="primary"
                  onClick={() => navigate("/activity/" + obj.id)}
                >
                  Abrir
                </Button>
              </>
            ),
          }));

          setDataTable(table);
          setTotal(response.data.total);
          setPageSize(response.data.per_page);
        }
      })
      .finally(() => setIsLoading(false));
  }
  // search states and functions

  const [search, setSearch] = useState("");
  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };
  const handleSearch = (search: string) => {
    setIsLoading(true);

    if (search !== "") {
      api
        .post("/v1/activity/all/search", {
          search: search,
        })
        .then((r) => {
          const data = r.data;
          const table = data.map((obj: any) => ({
            ...obj,
            key: obj.id,
            actions: (
              <>
                <Button
                  type="primary"
                  onClick={() => navigate("/activity/" + obj.id)}
                >
                  Abrir
                </Button>
              </>
            ),
          }));

          setDataTable(table);
          setTotal(table.length);
          setPageSize(table.length);
        })
        .finally(() => {
          setIsLoading(false);
        });
    } else {
      getData();
    }
  };

  // end of search

  useEffect(() => {
    api.get(`/v1/user/roles`).then((response) => {
      if (response.status === 200) {
        const data = response.data;
        setRoles(data.roles);
      }
    });
  }, []);

  const onChange: PaginationProps["onChange"] = (page) => {
    setCurrent(page);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [current]);

  const columns: TableColumnsType<DataType> = [
    {
      title: "Descrição",
      dataIndex: "name",
      key: "name",
      render: (name, record) => (
        <div id={`activities-description-${record.id}`}>{name}</div>
      ),
    },
    { title: "WP", dataIndex: "wp_name", key: "wp_name" },
    { title: "", dataIndex: "actions", key: "actions" },
  ];

  return (
    <>
      <Layout pageTitle="Lista de Atividades" breadcrumb="Home ⟶ Atividades">
        <Row gutter={16} className="page-row">
          <Col xs={24} md={6} className="mb-2">
            <CollapseCard title="Total">
              {!!total ? (
                <Title id="activities-total" level={2} className="total-card">
                  {total}
                </Title>
              ) : null}
            </CollapseCard>
          </Col>
          <Col xs={24} md={18} className="mb-2">
            <CollapseCard
              title={`Tabela de Atividades${
                roles.some((item) => ["Hired"].includes(item))
                  ? " com Contrato"
                  : ""
              }`}
              className="card-table-no-padding"
            >
              <Row>
                <Col span={24}>
                  <Col span={24} className="mb-2 text-right">
                    <SearchInput
                      type="text"
                      handleChange={handleChangeSearch}
                      value={search}
                      handleSearch={handleSearch}
                      placeholder="Busque por nome"
                      loading={isLoading}
                    />
                  </Col>
                  {dataTable.length > 0 ? (
                    <Table
                      columns={columns}
                      dataSource={dataTable}
                      pagination={false}
                      loading={isLoading}
                      size="small"
                    />
                  ) : (
                    <Table
                      className="table-no-data-rows"
                      columns={columns}
                      dataSource={[]}
                      pagination={false}
                      loading={isLoading}
                      size="small"
                    />
                  )}
                </Col>

                <Col
                  span={24}
                  className="text-center mt-4"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "5px",
                  }}
                >
                  <Pagination
                    current={current}
                    onChange={onChange}
                    total={total}
                    pageSize={pageSize}
                    showSizeChanger={false}
                  />
                </Col>
              </Row>
            </CollapseCard>
          </Col>
        </Row>
      </Layout>
    </>
  );
}

export default ActivityPage;
