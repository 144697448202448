import { Modal } from "antd";
import ContractCard from "../../components/CreateContract/contractCard";
import EditContractCard from "../../components/EditContract/contractCard";

function ContractModal({
  isModalOpen,
  isCreate,
  handleCancel,
  contractId,
  afterSubmit,
}: {
  isModalOpen: boolean;
  isCreate: boolean;
  handleCancel: () => void;
  contractId?: number;
  afterSubmit?: VoidFunction;
}) {
  return (
    <Modal
      title={`${isCreate ? "Criar" : "Editar"} Contrato`}
      closeIcon={true}
      footer={null}
      width={600}
      open={isModalOpen}
      onCancel={handleCancel}
    >
      {isCreate ? (
        <ContractCard onClosePopup={handleCancel} afterSubmit={afterSubmit} />
      ) : (
        <EditContractCard
          contractId={contractId || 1}
          onClosePopup={handleCancel}
          afterSubmit={afterSubmit}
        />
      )}
    </Modal>
  );
}

export default ContractModal;
