import { Button, Col, Row } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { statusList } from "../../constants/status";
import { useAddNewActivity } from "../../hooks/contracts/useAddNewActivity";
import { useFetchHiredActivities } from "../../hooks/hiredOrEmployee/useFetchHiredActivities";
import { Activity } from "../../models/Activity";
import { Hired } from "../../models/Hired";
import api from "../../services/Api";
import CollapseCard from "../Template/Card";
import "./hired.scss";
import HiredActivitiesModal from "./modal";
import { SelectActivity } from "./selectActivity";

type Props = {
  hired: Hired;
};

function HiredView({ hired }: Props) {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [refreshMany, setRefreshMany] = useState(new Date());

  const [newActivityId, setNewActivityId] = useState<any>();
  const [allActivities, setAllActivities] = useState<any[]>([]);

  const { handleAddActivity, refresh, isSubmitting } = useAddNewActivity();
  const { activities } = useFetchHiredActivities({
    hired,
    refresh,
    refreshMany,
  });

  const navigate = useNavigate();
  useEffect(() => {
    api.get("/v1/activity/activities-without-contract").then((response) => {
      setAllActivities(
        response.data.data.map((hired: any) => ({
          value: hired.id,
          label: hired.name,
        }))
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, refreshMany]);

  return (
    <>
      <Row gutter={16} className="page-row">
        <Col xs={24} md={10}>
          <CollapseCard title="Dados" className="hired_data">
            <Row className="hired_data_content">
              <div className="hired_data_content_row">
                <span>{hired?.name}</span>
                <span>{hired?.email}</span>
              </div>
              <div className="hired_data_content_row">
                <span>{hired?.description}</span>
                <span>{hired?.phone}</span>
              </div>
              <div className="see-employees">
                <Button
                  className="button-secondary"
                  onClick={() => navigate(`/hireds/${hired?.id}/employees`)}
                  id="see-employees"
                >
                  Ver colaboradores
                </Button>
              </div>
            </Row>
          </CollapseCard>
        </Col>

        <Col xs={24} lg={14}>
          <CollapseCard title="Atividades" className="hired_activities">
            <div className="hired_activities_content">
              {activities?.map((activity: Activity) => (
                <div
                  key={activity.id}
                  className="hired_activities_content_list"
                >
                  <span>{activity?.name}</span>
                  <span style={{ whiteSpace: "nowrap" }}>
                    {
                      statusList[
                        statusList.findIndex(
                          (item) => item.value === activity.status_id
                        )
                      ]?.label
                    }
                  </span>
                </div>
              ))}
              <div className="hired_activities_content_input-container">
                <SelectActivity
                  newActivityId={newActivityId}
                  setNewActivityId={setNewActivityId}
                  allActivities={allActivities}
                  setAllActivities={setAllActivities}
                />

                <Button
                  type="primary"
                  onClick={() =>
                    handleAddActivity({
                      hired,
                      newActivityId,
                      setNewActivityId,
                    })
                  }
                  disabled={!newActivityId || isSubmitting}
                >
                  Atribuir Atividade
                </Button>
                <Button onClick={() => setModalIsOpen(true)}>
                  Atribuir Mais
                </Button>
              </div>
            </div>
          </CollapseCard>
        </Col>
        {modalIsOpen ? (
          <HiredActivitiesModal
            hired={hired}
            allActivities={allActivities}
            isModalOpen={modalIsOpen}
            handleCancel={() => setModalIsOpen(false)}
            handleConfirm={() => {
              setRefreshMany(new Date());
              setModalIsOpen(false);
            }}
          />
        ) : null}
      </Row>
    </>
  );
}

export default HiredView;
