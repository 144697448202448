import { Button } from "antd";
import React, { Key } from "react";
import { NavigateFunction } from "react-router-dom";
import api from "../../../../services/Api";
import { TableType } from "../../../../types/cwa";
import { NavigateButton } from "../../components/NavigateButton";
import { navigateToActivities, navigateToWP } from "../navigate";

export const setMainTableData = ({
  setTables,
  project_id,
  selectedPage,
  navigate,
  setPageSize,
}: {
  setTables: React.Dispatch<React.SetStateAction<TableType>>;
  project_id: string | undefined;
  selectedPage: number;
  navigate: NavigateFunction;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
}) => {
  setTables((prevState) => ({
    ...prevState,
    table1: { ...prevState.table1, isLoading: true },
  }));

  api
    .get(`/v1/projects/${project_id}/cwas?page=${selectedPage}`)
    .then((response) => {
      if (response.status === 200) {
        const data = response.data.data;
        const table = data.map((obj: any) => ({
          ...obj,
          key: obj.id,
          actions: (
            <NavigateButton
              navFunction={navigateToWP}
              funcProps={{
                cwaId: obj.id,
                navigate,
                project_id,
              }}
              name="WPs"
            />
          ),
        }));
        setPageSize(response.data.per_page);
        setTables((prevState) => ({
          ...prevState,
          table1: {
            ...prevState.table1,
            data: table,
            total: response.data.total,
          },
        }));
      }

      setTables((prevState) => ({
        ...prevState,
        table1: { ...prevState.table1, isLoading: false },
      }));
    });
};

export const setMainTableDataWithSearch = ({
  setTables,
  project_id,
  search,
  selectedPage,
  navigate,
  setPageSize,
}: {
  setTables: React.Dispatch<React.SetStateAction<TableType>>;
  project_id: string | undefined;
  search?: string | number;
  selectedPage: number;
  navigate: NavigateFunction;
  setPageSize: React.Dispatch<React.SetStateAction<number>>;
}) => {
  setTables((prevState) => ({
    ...prevState,
    table1: { ...prevState.table1, isLoading: true },
  }));
  if (search !== "") {
    api
      .post(`/v1/projects/${project_id}/cwas/search`, { search: search })
      .then((response) => {
        const data = response.data;
        const table = data.map((obj: any) => ({
          ...obj,
          key: obj.id,
          actions: (
            <NavigateButton
              navFunction={navigateToWP}
              funcProps={{
                cwaId: obj.id,
                navigate,
                project_id,
              }}
              name="WPs"
            />
          ),
        }));
        setPageSize(data.length);
        setTables((prevState) => ({
          ...prevState,
          table1: {
            ...prevState.table1,
            data: table,
            total: data.length,
          },
        }));

        setTables((prevState) => ({
          ...prevState,
          table1: { ...prevState.table1, isLoading: false },
        }));
      });
  } else {
    api
      .get(`/v1/projects/${project_id}/cwas?page=${selectedPage}`)
      .then((response) => {
        if (response.status === 200) {
          const data = response.data.data;
          const table = data.map((obj: any) => ({
            ...obj,
            key: obj.id,
            actions: (
              <NavigateButton
                navFunction={navigateToWP}
                funcProps={{
                  cwaId: obj.id,
                  navigate,
                  project_id,
                }}
                name="WPs"
              />
            ),
          }));
          setPageSize(response.data.per_page);
          setTables((prevState) => ({
            ...prevState,
            table1: {
              ...prevState.table1,
              data: table,
              total: response.data.total,
            },
          }));
        }

        setTables((prevState) => ({
          ...prevState,
          table1: { ...prevState.table1, isLoading: false },
        }));
      });
  }
};

export const setExpandedData1 = async ({
  key,
  page,
  navigate,
  project_id,
  setTables,
}: {
  key: Key;
  page?: number | undefined;
  navigate: NavigateFunction;
  project_id: string | undefined;
  setTables: React.Dispatch<React.SetStateAction<TableType>>;
}) => {
  if (!key) return false;
  setTables((prevState) => ({
    ...prevState,
    table2: { ...prevState.table2, isLoading: true, isLoadingId: key },
  }));

  api.get("/v1/cwas/" + key + "/wps?page=" + (page || 1)).then((response) => {
    // setFetchingData2(truepage?: number | undefined)
    if (response?.status === 200) {
      const data1 = response.data.data.map((obj: any) => ({
        ...obj,
        key: obj.id,
        description: obj.description,
        subdisciplina: obj.sub_discipline_name,
        area: "Geral",
        state: "Finalizado",
        actions: (
          <NavigateButton
            navFunction={navigateToActivities}
            funcProps={{ wpId: obj.id, navigate, project_id }}
            name="atividades"
          />
        ),
      }));
      setTables((prevState) => ({
        ...prevState,
        table2: {
          ...prevState.table2,
          data: { ...prevState.table2.data, [key]: data1 },
          total: response.data.total,
        },
      }));
    }

    setTables((prevState) => ({
      ...prevState,
      table2: { ...prevState.table2, isLoading: false, isLoadingId: null },
    }));
  });
};

export const setExpandedData2 = async ({
  key,
  setTables,
}: {
  key: Key;
  setTables: React.Dispatch<React.SetStateAction<TableType>>;
}) => {
  const groupByKey = (list: any, key: any) =>
    (list || []).reduce(
      (hash: any, obj: any) => ({
        ...hash,
        [obj[key]]: (hash[obj[key]] || []).concat(obj),
      }),
      {}
    );

  if (!key) return false;

  setTables((prevState) => ({
    ...prevState,
    table3: { ...prevState.table3, isLoading: true, isLoadingId: key },
  }));

  await api.get(`v1/wps/${key}/activities`).then((response) => {
    if (response?.status === 200) {
      const types = Object.keys(groupByKey(response.data.data, "type"));
      let data2: any = [];
      if (types.includes("EWP"))
        data2.push({
          key: key + "-ewp",
          content: "Engenharia",
          type: "EWP",
        });
      if (types.includes("PWP"))
        data2.push({
          key: key + "-pwp",
          content: "Fornecimento",
          type: "PWP",
        });
      if (types.includes("CWP"))
        data2.push({
          key: key + "-cwp",
          content: "Construção",
          type: "CWP",
        });

      setTables((prevState) => ({
        ...prevState,
        table3: {
          ...prevState.table3,
          data: { ...prevState.table3.data, [key]: data2 },
          total: response.data.total,
        },
      }));
    }

    setTables((prevState) => ({
      ...prevState,
      table3: { ...prevState.table3, isLoading: false, isLoadingId: null },
    }));
  });
};

export const setExpandedData3 = async ({
  key,
  navigate,
  setTables,
}: {
  key: Key;
  navigate: NavigateFunction;
  setTables: React.Dispatch<React.SetStateAction<TableType>>;
}) => {
  if (!key) return false;

  setTables((prevState) => ({
    ...prevState,
    table4: { ...prevState.table4, isLoading: true, isLoadingId: key },
  }));
  const arr = key.toString().split("-");
  await api.get(`/v1/wps/${arr[0]}/activities/${arr[1]}`).then((response) => {
    if (response.status === 200) {
      const data = response.data.data.map((obj: any) => ({
        key: obj.id,
        status: obj.status,
        content: obj.name,
        actions: (
          <Button
            size="small"
            type="primary"
            onClick={() =>
              navigate("/activity/" + obj.id, {
                state: { isFromProject: true },
              })
            }
          >
            Abrir atividade
          </Button>
        ),
      }));
      setTables((prevState) => ({
        ...prevState,
        table4: {
          ...prevState.table4,
          data: { ...prevState.table4.data, [key]: data },
          total: response.data.total,
        },
      }));
    }

    setTables((prevState) => ({
      ...prevState,
      table4: { ...prevState.table4, isLoading: false, isLoadingId: null },
    }));
  });
};
