import { Card, Col, Row, Spin } from "antd";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MeasureCreation from "../../components/MeasureCreation";
import Layout from "../../components/Template/Layout";
import api from "../../services/Api";
import "./measurePage.scss";

function MeasureViewPage() {
  const { measure_id } = useParams();
  const [roles, setRoles] = useState<string[]>([]);

  useEffect(() => {
    api.get(`/v1/user/roles`).then((response) => {
      if (response.status === 200) {
        const data = response.data;
        setRoles(data.roles);
        // if (
        //   !data.roles.some((item: string) =>
        //     ["Administrador", "Owner"].includes(item)
        //   )
        // ) {
        //   navigate("/home");
        // }
      }
    });
  }, []);

  return (
    <>
      <Layout
        pageTitle="Detalhes da Medição"
        breadcrumb={`Medições ⟶ Medição #${measure_id}`}
      >
        {roles.length === 0 ? (
          <Row className="page-row">
            <Col xs={24} lg={12}>
              <Card>
                <Spin />
              </Card>
            </Col>
          </Row>
        ) : (
          <MeasureCreation hasList userRoles={roles} />
        )}
      </Layout>
    </>
  );
}

export default MeasureViewPage;
