import { TableColumnsType } from "antd";
import { formatCurrency } from "../../../utils/formatCurrency";

interface DataType {
  description: string;
  activity_id: string;
  status_id: number;
  hired_id: number;
  quality_approved_by: string | null;
  quality_approved_date: string | null;
  control_approved_by: string | null;
  control_approved_date: string | null;
  measure_approved_by: string | null;
  measure_approved_date: string | null;
  quality_refused_by: string | null;
  quality_refused_date: string | null;
  control_refused_by: string | null;
  control_refused_date: string | null;
  measure_refused_by: string | null;
  measure_refused_date: string | null;
  estimate_hours: string;
  contract_reference: string | null;
  contract_id: number;
  parent_activity_id: number | null;
  percent_complete: string;
  code: string;
  predecessor: string | null;
  sub_discipline_id: number;
  project_information_id: number;
  unit_id: number | null;
  percentual: string | null;
  cost: string | null;
  quantity: string | null;
}

export const columns: TableColumnsType<DataType> = [
  { title: "Descrição", dataIndex: "description", key: "description" },
  {
    title: "Atividade",
    dataIndex: "status_activity_name",
    key: "status_activity_name",
    width: 220,
  },

  {
    title: "Projeto",
    dataIndex: "project_name",
    key: "project_name",
    width: 150,
  },
  { title: "Unidade", dataIndex: "unit_abbreviation", key: "unit_id" },
  {
    title: "Percentual",
    dataIndex: "percentual",
    key: "percentual",
    render: (name, record) => (
      <div>{name ? name.toString().replace(/\./g, ",") + "%" : "-"}</div>
    ),
  },

  { title: "Quantidade", dataIndex: "quantity", key: "quantity" },
  {
    title: "Custo",
    dataIndex: "cost",
    key: "cost",
    render: (name, record) => <div>{name ? formatCurrency(name) : "-"}</div>,
    width: 150,
  },
  /*   {
    title: "Subdisciplina",
    dataIndex: "sub_discipline_name",
    key: "sub_discipline_name",
  }, */
  /*   {
    title: "Percentual",
    dataIndex: "percent_complete",
    key: "percent_complete",
  }, */
  /*   { title: "Código", dataIndex: "code", key: "code" },
   */
];

export default columns;
