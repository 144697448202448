import { Form, Select } from "antd";
import "./monthSelect.scss";

type Props = {
  handleChange: (e: string) => void;
  label?: string;
};
export default function MonthSelect({ handleChange, label }: Props) {
  return (
    <>
      <div className="container-month-select">
        <Form layout="vertical">
          <Form.Item label={label}>
            <Select
              defaultValue={"0"}
              style={{ width: 150 }}
              onChange={handleChange}
              options={[
                { value: "0", label: "Todos" },
                { value: "1", label: "Janeiro" },
                { value: "2", label: "Fevereiro" },
                { value: "3", label: "Março" },
                { value: "4", label: "Abril" },
                { value: "5", label: "Maio" },
                { value: "6", label: "Junho" },
                { value: "7", label: "Julho" },
                { value: "8", label: "Agosto" },
                { value: "9", label: "Setembro" },
                { value: "10", label: "Outubro" },
                { value: "11", label: "Novembro" },
                { value: "12", label: "Dezembro" },
              ]}
            />
          </Form.Item>
        </Form>
      </div>
    </>
  );
}
