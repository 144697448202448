import { useState } from "react";
import api from "../../config/axios";

import { Hired } from "../../models/Hired";
import { toastAlert } from "../../utils/toastAlert";

type Props = {
  hired: Hired;
  newActivityId: number;
  setNewActivityId: (number: number | undefined) => void;
};
export const useAddNewActivity = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [refresh, setRefresh] = useState<Date>();

  const handleAddActivity = async ({
    hired,
    newActivityId,
    setNewActivityId,
  }: Props) => {
    setIsSubmitting(true);
    await api
      .post("/v1/contracts/create", {
        hired_id: hired.id,
        status: "Assinado",
        activity_id: Number(newActivityId),
        active: true,
      })
      .then((response) => {
        setNewActivityId(undefined);
        setRefresh(new Date());
        toastAlert({
          type: "success",
          message:
            "Atividade foi atribuída e já pode ser vista na lista de atividades da contratada.",
          title: "Atividade atribuída",
        });
      })
      .catch((error) => {
        toastAlert({
          type: "error",
          message:
            "Atividade não pôde ser atribuída. Verifique sua conexão e se os campos foram preenchidos corretamente.",
          title: "Erro ao atribuir atividade",
        });
      })
      .finally(() => setIsSubmitting(false));
  };

  return { handleAddActivity, setIsSubmitting, refresh, isSubmitting };
};
