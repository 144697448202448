import { useCallback, useEffect, useState } from "react";
import api from "../../config/axios";

type Props = {
  wp_id?: string | number;
  refresh: null | Date;
  loadingInitialValue?: boolean;
};

export const useFetchWp = ({ wp_id, refresh, loadingInitialValue }: Props) => {
  const [wp, setWp] = useState<any>();
  const [isFetchingWp, setIsFetching] = useState(!!loadingInitialValue);
  const [error, setError] = useState<Error | null>(null);

  const returnWp = useCallback(() => {
    if (wp_id) {
      setIsFetching(true);
      api
        .get(`v1/wps/${wp_id}`)
        .then((response) => {
          setWp(response.data);
        })
        .catch((err) => {
          setError(err);
        })
        .finally(() => {
          setIsFetching(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wp_id, refresh]);

  useEffect(() => {
    returnWp();
  }, [returnWp]);

  return { wp, isFetchingWp, error };
};
