import type { PaginationProps } from "antd";
import {
  Col,
  ConfigProvider,
  Pagination,
  Row,
  Spin,
  Table,
  Typography,
} from "antd";
import { Key, ReactNode, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MeasureModal from "../../components/CwaMeasureModal/modal";
import SearchInput from "../../components/SearchInput";
import CollapseCard from "../../components/Template/Card";
import Layout from "../../components/Template/Layout";
import api from "../../services/Api";
import { TableType } from "../../types/cwa";
import {
  ExpandedTable2,
  ExpandedTable3,
  ExpandedTable4,
} from "./components/expandedRows";
import "./cwaPage.scss";
import { mainTableColumns, mainTableHiredColumns } from "./utils/columns";
import {
  setExpandedData1,
  setExpandedData2,
  setExpandedData3,
  setMainTableData,
  setMainTableDataWithSearch,
} from "./utils/setTableData";

const { Title } = Typography;

function CwaPage() {
  const [tables, setTables] = useState<TableType>({
    table1: { data: [], isLoading: true, isLoadingId: null, total: 0 },
    table2: { data: [], isLoading: true, isLoadingId: null, total: 0 },
    table3: { data: [], isLoading: true, isLoadingId: null, total: 0 },
    table4: { data: [], isLoading: true, isLoadingId: null, total: 0 },
  });

  const [roles, setRoles] = useState<string[]>([]);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const { project_id } = useParams();
  const [projectName, setProjectName] = useState(undefined);
  const [selectedPage, setSelectedPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [currentKeys, setCurrentKeys] = useState<any[]>([]);
  const navigate = useNavigate();
  const canNavigate =
    roles.length > 0 &&
    ["Administrador", "Owner", "Subowner"].some((item) =>
      roles[0].includes(item)
    );
  // search states and functions

  const [search, setSearch] = useState("");
  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };
  const handleSearch = (search: string) => {
    setMainTableDataWithSearch({
      setTables,
      project_id,
      search,
      selectedPage,
      navigate,
      setPageSize,
    });
  };

  // end of search

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const expandedRowRender = (cwa: any): ReactNode => {
    const expandedRowRender = (wp: any): ReactNode => {
      const expandedRowRender = (activityType: any): ReactNode => {
        return (
          <ExpandedTable4
            table={tables.table4}
            expandedRowRender={expandedRowRender}
            record={activityType}
            canNavigate={canNavigate}
          />
        );
      };

      return (
        <ExpandedTable3
          table={tables.table3}
          setExpandedData3={({ key }: { key: Key }) =>
            setExpandedData3({ key, setTables, navigate })
          }
          expandedRowRender={expandedRowRender}
          record={wp}
        />
      );
    };

    return (
      <ExpandedTable2
        table={tables.table2}
        setExpandedData1={async ({
          key,
          page,
        }: {
          key: Key;
          page?: number | undefined;
        }) =>
          await setExpandedData1({
            key,
            page,
            setTables,
            navigate,
            project_id,
          })
        }
        setExpandedData2={({ key }: { key: Key }) =>
          setExpandedData2({ key, setTables })
        }
        expandedRowRender={expandedRowRender}
        record={cwa}
        canNavigate={canNavigate}
      />
    );
  };

  useEffect(() => {
    api.get(`/v1/user/roles`).then((response) => {
      if (response.status === 200) {
        const data = response.data;
        setRoles(data.roles);
      }
    });
  }, []);

  useEffect(() => {
    setMainTableData({
      setTables,
      project_id,
      selectedPage,
      navigate,
      setPageSize,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPage]);

  useEffect(() => {
    if (project_id)
      api.get("/v1/projects/" + project_id).then((response) => {
        if (response.status === 200) {
          const data = response.data;
          setProjectName(data.name);
        }
      });
  }, [project_id]);

  const customizeRenderEmpty = () => (
    <div style={{ textAlign: "center" }}>Sem dados</div>
  );

  const onChange: PaginationProps["onChange"] = (page) => {
    setSelectedPage(page);
  };

  return (
    <>
      <Layout
        pageTitle="CWA - Áreas do Projeto"
        breadcrumb={
          projectName ? (
            `${projectName} ⟶ CWA`
          ) : (
            <>
              <Spin size="small" className="breadcrumb-loading" /> {" ⟶ CWA"}
            </>
          )
        }
      >
        <Row gutter={16} className="page-row">
          <Col xs={24} md={6} className="mb-2">
            <CollapseCard title="Total">
              {!!tables.table1.total ? (
                <Title level={2} className="total-card">
                  {tables.table1.total}
                </Title>
              ) : null}
            </CollapseCard>
          </Col>
          <Col xs={24} md={18} className="mb-2">
            <CollapseCard
              title="Tabela de CWA"
              className="card-table-no-padding"
            >
              <Row>
                <Col span={24} style={{ overflow: "auto" }}>
                  <ConfigProvider renderEmpty={customizeRenderEmpty}>
                    <Col span={24} className="mb-2 text-right">
                      <SearchInput
                        type="text"
                        handleChange={handleChangeSearch}
                        value={search}
                        handleSearch={handleSearch}
                        placeholder="Busque pelo código ou descrição"
                        loading={tables.table1.isLoading}
                        classe="cwa-search"
                      />
                    </Col>

                    <Col span={24} style={{ overflow: "auto" }}>
                      {roles.length > 0 ? (
                        <Table
                          className="table-default-style"
                          columns={
                            canNavigate
                              ? mainTableColumns
                              : mainTableHiredColumns
                          }
                          expandable={{
                            expandedRowRender,
                            onExpandedRowsChange: (keys) => {
                              if (currentKeys.length < keys.length) {
                                setExpandedData1({
                                  key: keys[keys.length - 1],
                                  setTables,
                                  navigate,
                                  project_id,
                                });
                              }
                              setCurrentKeys([...keys]);
                            },
                          }}
                          loading={tables.table1.isLoading}
                          dataSource={tables.table1.data}
                          pagination={false}
                          // scroll={{ y: 350 }}
                          size="small"
                          style={{ minWidth: "700px" }}
                        />
                      ) : (
                        <Table
                          className="table-default-style"
                          columns={mainTableHiredColumns}
                          loading={true}
                          dataSource={[]}
                          pagination={false}
                          size="small"
                          style={{ minWidth: "600px" }}
                        />
                      )}
                    </Col>
                  </ConfigProvider>
                </Col>
              </Row>
              <Row
                style={{
                  display: "flex",
                  justifyContent: "center",
                  padding: "5px",
                }}
              >
                <Pagination
                  current={selectedPage}
                  onChange={onChange}
                  pageSize={pageSize}
                  total={tables.table1.total}
                />
              </Row>
            </CollapseCard>
            {isModalOpen ? (
              <MeasureModal
                isModalOpen={isModalOpen}
                handleCancel={handleCancel}
              />
            ) : null}
          </Col>
        </Row>
      </Layout>
    </>
  );
}

export default CwaPage;
