import { Spin, Table, TableColumnsType } from "antd";
import { useEffect, useState } from "react";
import { statusList } from "../../constants/status";

const activityColumns: TableColumnsType<any> = [
  {
    title: "Etapa",
    dataIndex: "percentual_group_category",
    key: "percentual_group_category",
    render: (name) => <div>{name}</div>,
  },
  { title: "Step de medição", dataIndex: "atividades", key: "atividades" },

  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (name, record) => (
      <div>
        {statusList[statusList.findIndex((item) => item.value === name)]?.label}
      </div>
    ),
  },
  { title: "Responsável", dataIndex: "responsavel", key: "responsavel" },
  {
    title: "Peso",
    dataIndex: "percentual",
    key: "percentual",
    render: (name) => <div>{+name}%</div>,
  },

  { title: "", dataIndex: "actions", width: 110, key: "actions" },
];

export function ExpandedTable({
  table,
  record,
  setExpandedData,
}: {
  table: {
    data: any;
    isLoading: boolean;
    isLoadingId: any;
    total: number;
  };
  record: any;
  setExpandedData: any;
}) {
  const [currentPage, setCurrentPage] = useState(1);
  const parentRecordId = record.id;

  useEffect(() => {
    setExpandedData({ key: record.id, page: currentPage });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  return (
    <Table
      columns={activityColumns}
      dataSource={table.data[record.id] || []}
      onRow={(record, rowIndex) => {
        return {
          className:
            parentRecordId === table?.isLoadingId ? "ant-spin-blur" : "",
        };
      }}
      pagination={{
        position: ["bottomCenter"],
        current: currentPage,
        pageSize: 30,
        onChange(page, pageSize) {
          setCurrentPage(page);
        },
        total: table.total,
        showSizeChanger: false,
      }}
      locale={emptyText(parentRecordId, table?.isLoadingId)}
    />
  );
}

const emptyText = (parentRecordId: any, loadingDataId: any) => ({
  emptyText: parentRecordId === loadingDataId ? <Spin /> : <>Sem dados</>,
});
