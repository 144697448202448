import ptBR from "antd/locale/pt_BR";
import React from "react";
import ReactDOM from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import "./assets/css/default.scss";
import "./assets/css/overrides.scss";
import ActivityPage from "./pages/ActivityPage";
import ActivityViewPage from "./pages/ActivityViewPage";
import ChartsPage from "./pages/ChartsPage";
import ContractsPage from "./pages/ContractsPage";
import CwaEditPage from "./pages/CwaEditPage";
import CwaPage from "./pages/CwaPage";
import DashboardPage from "./pages/DashboardPage";
import EmployeePage from "./pages/EmployeePage";
import ErrorPage from "./pages/ErrorPage";
import ForgotPassword from "./pages/ForgotPassword";
import HiredPage from "./pages/HiredPage";
import HiredViewPage from "./pages/HiredViewPage";
import HomePage from "./pages/HomePage";
import Login from "./pages/Login";
import MeasureCreationPage from "./pages/MeasureCreationPage";
import MeasurePage from "./pages/MeasurePage";
import MeasureViewPage from "./pages/MeasureViewPage";
import NotificationPage from "./pages/NotificationPage";
import ProjectsPage from "./pages/ProjectsPage";
import RequisitionsPage from "./pages/RequisitionsPage";
import UserProfile from "./pages/UserProfile";
import WpPage from "./pages/WpPage";
import WpViewPage from "./pages/WpViewPage";

import { ConfigProvider } from "antd";
import ContractViewPage from "./pages/ContractViewPage";
import AuthWrapper from "./utils/AuthWrapper";
import ReportPage from "./pages/ReportPage";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route path="/error/:error?" element={<ErrorPage />} />
      <Route path="/" element={<Login />} />
      <Route path="login" element={<Login />} />
      <Route path="reset-password" element={<ForgotPassword />} />
      <Route path="project/:project_id/cwa" element={<CwaPage />} />
      <Route
        path="project/:project_id/wp/view/:wp_id"
        element={<WpViewPage />}
      />
      <Route path="dashboard" element={<DashboardPage />} />
      <Route path="home" element={<HomePage />} />
      <Route path="notifications" element={<NotificationPage />} />
      <Route path="projects" element={<ProjectsPage />} />
      <Route path="contracts" element={<ContractsPage />} />
      <Route path="contract/:contract_id" element={<ContractViewPage />} />
      <Route path="project/:project_id/cwas" element={<CwaPage />} />
      <Route path="project/:project_id/wps/:cwa_id" element={<WpPage />} />
      {/* <Route path="project/:project_id/wps/:cwa_id/requisitions" element={<RequisitionsPage />} /> */}
      <Route path="project/:project_id/cwas_edit" element={<CwaEditPage />} />
      <Route path="requisitions" element={<RequisitionsPage />} />
      <Route path="charts" element={<ChartsPage />} />
      <Route path="measures" element={<MeasurePage />} />
      <Route path="measure/:measure_id" element={<MeasureViewPage />} />
      <Route path="measure-creation" element={<MeasureCreationPage />} />
      <Route path="activities" element={<ActivityPage />} />
      <Route path="activity/:activity_id" element={<ActivityViewPage />} />
      <Route path="hireds" element={<HiredPage />} />
      <Route path="hireds/:hired_id" element={<HiredViewPage />} />
      <Route path="hireds/:hired_id/employees" element={<EmployeePage />} />

      <Route path="report" element={<ReportPage />} />

      <Route path="profile" element={<UserProfile />} />
      {/* <Route path="hireds/:hired_id" element={<CreateHired />} /> */}
      <Route path="*" element={<ErrorPage errorType={404} />} />
    </Route>
  )
);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <AuthWrapper>
      <HelmetProvider>
        <ConfigProvider locale={ptBR}>
          <RouterProvider router={router} />
        </ConfigProvider>
      </HelmetProvider>
    </AuthWrapper>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
