import { Input as AntdInput, Button, Col, Form, InputRef, message } from "antd";
import api from "../../../services/Api";

import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";
import { ReactNode, forwardRef, useRef, useState } from "react";

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { FormProvider } from "../../../components/form/FormProvider";
import { Input } from "../../../components/form/fields";
import { LoginFormType, LoginSchema } from "../../../schemas/login";
import "../login.scss";

export function LoginForm() {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const methods = useForm<LoginFormType>({
    resolver: zodResolver(LoginSchema),
  });

  const {
    handleSubmit,
    watch,
    setValue,
    trigger,
    formState: { errors },
  } = methods;
  const values = watch();
  const inputRef = useRef<InputRef>(null);

  const handleFormSubmit = (values: LoginFormType) => {
    setIsLoading(true);

    api
      .post(
        "/v1/login", //ver como ler do .env
        {
          email: values.email,
          password: values.password,
        }
      )
      .then((response) => {
        if (response.status === 200) {
          localStorage.setItem("access_token", response.data.plainTextToken);
          localStorage.setItem("user_id", response.data.user.id);
          localStorage.setItem("user_name", response.data.user.name);
          localStorage.setItem("user_email", response.data.user.email);
          localStorage.setItem("user_image", response.data.user.avatar.image);
          localStorage.setItem("user_photo", response.data.user.user_photo);
          localStorage.setItem("user_role", response.data.user.role);
          localStorage.setItem(
            "user_letters",
            response.data.user.avatar.letters
          );
          localStorage.setItem("user_hired", response.data.user.hired_id);
          window.location.href = "/home";
        }
      })
      .catch((error) => {
        if (error.response && error.response.status === 401) {
          message.error(error.response.data.message);
        } else {
          message.error(
            "Ocorreu um erro ao processar a solicitação, tente novamente mais tarde."
          ); // Mensagem de erro padrão
        }
        setIsLoading(false);
      });
  };

  return (
    <FormProvider
      methods={methods}
      form={form}
      submitOnEnter
      onFinish={handleSubmit(handleFormSubmit)}
    >
      <Col span={24}>
        <Input
          id="login-email"
          name="email"
          type="email"
          placeholder="E-mail"
          onKeyDown={(event) =>
            event.code === "Enter"
              ? !values.password && inputRef.current?.focus()
              : {}
          }
        />
      </Col>

      <Col span={24}>
        <PasswordInput
          showPassword={showPassword}
          ref={inputRef}
          setShowPassword={setShowPassword}
          help={
            errors["password"]
              ? (errors["password"]?.message as string)
              : undefined
          }
          validateStatus={!!errors["password"] ? "error" : undefined}
          value={values["password"]}
          onChange={(event) => {
            setValue("password", event.target.value);
            errors["password"] && trigger("password");
          }}
        />
      </Col>

      <Col span={24} className="mt-2">
        <Button
          id="login-submit"
          className="submit pt-1 pb-1"
          htmlType="submit"
          loading={isLoading}
        >
          Entrar
        </Button>
      </Col>
    </FormProvider>
  );
}
type Props = {
  showPassword: boolean;
  setShowPassword: React.Dispatch<React.SetStateAction<boolean>>;
  help: ReactNode;
  validateStatus:
    | ""
    | "error"
    | "success"
    | "warning"
    | "validating"
    | undefined;
  value: string | number | undefined;
  onChange: React.ChangeEventHandler<HTMLInputElement> | undefined;
};

const PasswordInput = forwardRef<InputRef, Props>((props, ref) => {
  const {
    showPassword,
    setShowPassword,
    help,
    validateStatus,
    value,
    onChange,
  } = props;
  return (
    <Form.Item name="password" help={help} validateStatus={validateStatus}>
      <AntdInput
        id="login-password"
        value={value}
        defaultValue={value}
        onChange={onChange}
        size="small"
        name="password"
        type={showPassword ? "text" : "password"}
        placeholder="Senha"
        ref={ref}
        suffix={
          <div
            onClick={() => setShowPassword(!showPassword)}
            style={{ cursor: "pointer" }}
          >
            {showPassword ? (
              <EyeInvisibleOutlined style={{ paddingRight: 8 }} />
            ) : (
              <EyeOutlined style={{ paddingRight: 8 }} />
            )}
          </div>
        }
        className="mt-2"
        style={{ maxWidth: 250 }}
      />
    </Form.Item>
  );
});
