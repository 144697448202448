import { Button, Col, Popconfirm, Row, Spin, Timeline, UploadFile } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFetchActivityHistory } from "../../hooks/activity/useFetchActivityHistory";
import { useFetchComments } from "../../hooks/activity/useFetchComments";
import { usePostComment } from "../../hooks/activity/usePostComment";
import { useFetchFilesByActivity } from "../../hooks/files/useFetchFilesByActivity";
import { Activity } from "../../models/Activity";
import { Measure } from "../../models/Measure";
import api from "../../services/Api";
import { toastAlert } from "../../utils/toastAlert";
import CommentCard from "../CommentCard";
import { MeasureFileListCard } from "../MeasureFileListCard";
import CollapseCard from "../Template/Card";
import "./measurePage.scss";
import MeasureModal from "./modal";

function MeasureCreation({
  hasList,
  isPopup,
  activity,
  userRoles,
}: {
  hasList?: boolean;
  isPopup?: boolean;
  activity?: Activity;
  userRoles?: string[];
}) {
  const { measure_id } = useParams();

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [comment, setComment] = useState<string>("");
  const [measure, setMeasure] = useState<Measure | undefined>();

  const [refreshCommentList, setRefreshCommentList] = useState(new Date());
  const [refreshFileList, setRefreshFileList] = useState(new Date());
  const [refreshHistory, setRefreshHistory] = useState(new Date());
  const [roles, setRoles] = useState<string[]>(userRoles || []);
  const isRefused = measure && measure.status_id === 3;
  const status = isRefused ? "error" : "finish";

  const [isModalOpen, setIsModalOpen] = useState(false);
  const activity_id = measure?.activity_id;

  useEffect(() => {
    if (!userRoles) {
      api.get(`/v1/user/roles`).then((response) => {
        if (response.status === 200) {
          const data = response.data;
          setRoles(data.roles);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (measure_id) {
      setLoading(true);
      api.get("/v1/measure/" + measure_id).then((response) => {
        if (response.status === 200) {
          setLoading(false);
          const data = response.data;
          setMeasure({
            ...data,
            key: data.id,
            content: data.description,
          });
        }
      });
    }
  }, [measure_id]);

  const { history } = useFetchActivityHistory({
    activity_id: measure?.activity_id,
    refresh: refreshHistory,
  });

  const { isLoadingFiles } = useFetchFilesByActivity({
    activity_id: measure?.activity_id,
    setFileList,
    refreshFileList,
  });

  const { comments } = useFetchComments({
    activity_id: activity_id,
    refresh: refreshCommentList,
  });
  const { postComment, isFetching } = usePostComment();
  const handleSendComment = () => {
    const formData = {
      message: comment,
      activity_id: activity_id,
    };
    if (!isFetching) postComment({ formData });
  };

  useEffect(() => {
    setRefreshCommentList(new Date());
    setComment("");
  }, [isFetching]);

  const handleAprove = () => {
    api
      .patch(`/v1/measure/${measure_id}/aprove`, {
        measure_id,
      })
      .then((response) => {
        toastAlert({
          type: "success",
          message: `Já é possível encontrá-la na aba de medições.`,
          title: "Medição aprovada",
        });

        navigate("/measures");
      })
      .catch((error) => {
        toastAlert({
          type: "error",
          message: `Verifique sua conexão e tente novamente.`,
          title: "Não foi possível aprovar a medição",
        });
      });
  };

  const getCurrentProgress = () => {
    let progress = -1;

    if (measure?.measure_approved_by) progress = 2;
    else if (measure?.control_approved_by) progress = 1;
    else if (measure?.quality_approved_by) progress = 0;
    if (isRefused) progress = progress + 1;

    return progress;
  };

  const userCanAprove = () => {
    if (roles.length === 0) return false;
    else if (["Administrador", "Owner"].some((item) => roles[0].includes(item)))
      return true;
    else return false;
  };

  // const userCanAprove = () => {
  //   if (roles.length === 0) return false;
  //   else if (["Administrador", "Owner"].some((item) => roles[0].includes(item)))
  //     return true;
  //   else if (roles[0].includes("Subowner")) {
  //     let currentStep;

  //     if (measure?.measure_approved_by) return false;
  //     else if (measure?.control_approved_by) currentStep = "cost";
  //     else if (measure?.quality_approved_by) currentStep = "control";
  //     else currentStep = "quality";

  //     return roles[0].includes("Subowner_" + currentStep);
  //   } else return false;
  // };

  return (
    <Row gutter={16} className="page-row">
      <Col xs={24} lg={12}>
        <CollapseCard
          title="Informações"
          className="measure_creation-card"
          style={{ marginBottom: isPopup ? 0 : 20 }}
        >
          <Row style={{ display: "flex", justifyContent: "space-between" }}>
            <Col span={24}>
              <Col>
                <Row
                  style={{
                    display: "flex",
                    gap: 8,
                    justifyContent: "space-between",
                    width: "100%",
                    padding: 16,
                  }}
                >
                  {!!measure ? (
                    <>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          gap: 8,
                          justifyContent: "space-between",
                        }}
                      >
                        <div>{measure?.description}</div>
                        <div>Tipo: {measure?.type}</div>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                          gap: 8,
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          Status:{" "}
                          {measure?.measure_approved_by
                            ? "Aprovada"
                            : isRefused
                            ? "Recusada"
                            : "Avaliação pendente"}
                        </div>

                        <Button
                          size="small"
                          onClick={() =>
                            navigate("/activity/" + measure?.activity_id)
                          }
                          className="activity_creation-submit"
                          type="primary"
                        >
                          Ir para atividade
                        </Button>
                      </div>
                    </>
                  ) : (
                    <>
                      <Spin />
                    </>
                  )}
                </Row>
              </Col>
            </Col>
          </Row>
        </CollapseCard>
        {/* 
        <CollapseCard
          title="Progresso para aprovação"
          style={{ marginBottom: 20 }}
        >
          <Steps
            current={getCurrentProgress()}
            status={status}
            className="measure-steps"
            items={[
              {
                title: "Qualidade",
                icon: <BsPatchCheck />,
              },
              {
                title: "Controle",
                icon: <LuShieldCheck />,
              },
              {
                title: "Pagamento",
                icon: <IoMdCheckmarkCircleOutline />,
              },
            ]}
          />
        </CollapseCard> */}

        <MeasureFileListCard
          fileList={fileList}
          canCollapse
          isLoading={isLoadingFiles}
          refreshList={() => {
            setRefreshFileList(new Date());
            setRefreshHistory(new Date());
          }}
          roles={roles}
          showButton={measure?.status_id !== 4}
          hideActions={measure?.status_id === 4}
        />

        {/* <FileListCard
          cardTitle="Arquivos da Atividade"
          fileList={fileList}
          isPopup={isPopup}
          isLoading={isLoadingFiles}
          refreshList={() => {
            setRefreshFileList(new Date());
            setRefreshHistory(new Date());
          }}
        /> */}

        <CollapseCard
          title="Histórico da Atividade"
          style={{ marginBottom: isPopup ? 0 : 20 }}
        >
          <Row
            style={{
              display: "flex",
              gap: 8,
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Timeline
              mode="left"
              reverse
              items={history?.map((item) => ({
                label: `${new Date(item.created_at).toLocaleDateString(
                  "pt-BR"
                )} ${new Date(item.created_at).toLocaleTimeString("pt-BR", {
                  hour: "2-digit",
                  minute: "2-digit",
                })}`,
                children: item.message,
              }))}
            />
          </Row>
        </CollapseCard>
      </Col>

      <Col xs={24} lg={12} className="mb-2">
        {userCanAprove() ? (
          <CollapseCard
            title={`Ações`}
            style={{
              marginBottom: isPopup ? 0 : 20,
              width: "100%",
            }}
          >
            <Row style={{ display: "flex", justifyContent: "space-between" }}>
              <Col span={24}>
                <Col>
                  {loading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        padding: 16,
                      }}
                    >
                      <Spin />
                    </div>
                  ) : (
                    <Row
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 8,
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          gap: 8,
                          justifyContent: "space-between",
                        }}
                      >
                        {userCanAprove() ? (
                          <div
                            style={{
                              gap: 8,
                              display: "flex",
                              justifyContent: "flex-end",
                              width: "100%",
                            }}
                          >
                            {isRefused ? (
                              <span>
                                Medição foi reprovada, não há ações disponíveis
                              </span>
                            ) : measure && !measure?.measure_approved_by ? (
                              <>
                                <Popconfirm
                                  title="Aprovamento de medição"
                                  description="Tem certeza que deseja aprovar essa medição"
                                  onConfirm={handleAprove}
                                  onCancel={() => {}}
                                  okText="Sim"
                                  cancelText="Não"
                                >
                                  <Button
                                    type="primary"
                                    className="aprove-measure"
                                  >
                                    Aprovar Medição
                                  </Button>
                                </Popconfirm>

                                <Button
                                  type="primary"
                                  onClick={() => setIsModalOpen(true)}
                                  className="refuse-measure"
                                >
                                  Reprovar Medição
                                </Button>
                              </>
                            ) : (
                              <div>Medição foi aprovada</div>
                            )}
                          </div>
                        ) : (
                          <div>Sem ações permitidas</div>
                        )}
                      </div>
                    </Row>
                  )}
                </Col>
              </Col>
            </Row>
          </CollapseCard>
        ) : null}

        <CommentCard
          commentList={comments}
          inputComment={comment}
          setInputComment={setComment}
          handleSendComment={handleSendComment}
          uploading={isFetching}
        />

        {isModalOpen ? (
          <MeasureModal
            isModalOpen={isModalOpen}
            handleClose={() => setIsModalOpen(false)}
          />
        ) : null}
      </Col>
    </Row>
  );
}

export default MeasureCreation;
