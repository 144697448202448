import { Drawer as AntdDrawer, Col, Spin, Typography } from "antd";
import { useEffect, useState } from "react";
import { AiOutlineDatabase } from "react-icons/ai";
import { HiOutlineDocumentReport } from "react-icons/hi";
import {
  FaPencilRuler,
  FaRegFolderOpen,
  FaTasks,
  FaUser,
} from "react-icons/fa";
// import { IoMdListBox } from "react-icons/io";
import { IoMdListBox } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import api from "../../../services/Api";
import "./drawer.scss";

const { Text } = Typography;

function Drawer({ open, onClose }: { open: boolean; onClose: VoidFunction }) {
  const navigate = useNavigate();
  const [roles, setRoles] = useState<string[]>([]);

  useEffect(() => {
    api.get(`/v1/user/roles`).then((response) => {
      if (response.status === 200) {
        const data = response.data;
        setRoles(data.roles);
      }
    });
  }, []);

  return (
    <AntdDrawer
      title="Menu"
      className="drawer"
      placement="right"
      open={open}
      onClose={onClose}
    >
      {roles.length > 0 ? (
        <>
          <Col className="drawer-menu" onClick={() => navigate("/projects")}>
            <AiOutlineDatabase size={"1.5em"} style={{ width: "32px" }} />
            <Text>Projetos</Text>
          </Col>

          <Col className="drawer-menu" onClick={() => navigate("/report")}>
            <HiOutlineDocumentReport size={"1.5em"} style={{ width: "32px" }} />
            <Text>Relatórios</Text>
          </Col>

          <Col
            className="drawer-menu"
            onClick={() => navigate("/requisitions")}
          >
            <IoMdListBox size={"1.5em"} style={{ width: "32px" }} />
            <Text>Requisições</Text>
          </Col>

          <Col className="drawer-menu" onClick={() => navigate("/measures")}>
            <FaPencilRuler size={"1.5em"} style={{ width: "32px" }} />
            <Text>Medições</Text>
          </Col>

          <Col className="drawer-menu" onClick={() => navigate("/contracts")}>
            <FaRegFolderOpen size={"1.5em"} style={{ width: "32px" }} />
            <Text>Contratos</Text>
          </Col>

          <Col className="drawer-menu" onClick={() => navigate("/activities")}>
            <FaTasks size={"1.5em"} style={{ width: "32px" }} />
            <Text>Atividades</Text>
          </Col>
          {roles.some((item) => ["Administrador", "Owner"].includes(item)) ? (
            <Col className="drawer-menu" onClick={() => navigate("/hireds")}>
              <FaUser size={"1.5em"} style={{ width: "32px" }} />
              <Text>Contratadas</Text>
            </Col>
          ) : null}

          {/* <Col className="drawer-menu" onClick={() => navigate("/hireds")}>
            <FaUser size={"1.5em"} style={{ width: "32px" }} />
            <Text>Contratadas</Text>
          </Col> */}
        </>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            padding: 24,
          }}
        >
          <Spin />
        </div>
      )}
    </AntdDrawer>
  );
}

export default Drawer;
