import { Button, Input, Modal } from "antd";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "../../services/Api";
import { toastAlert } from "../../utils/toastAlert";
import "./measurePage.scss";

const { TextArea } = Input;

function MeasureModal({
  isModalOpen,
  handleClose,
}: {
  isModalOpen: boolean;
  handleClose: VoidFunction;
}) {
  const navigate = useNavigate();
  const { measure_id } = useParams();
  const [message, setMessage] = useState("");
  const [loading, setloading] = useState(false);

  const handleCancel = () => {
    handleClose();
  };

  const handleRefuse = () => {
    setloading(true);
    api
      .patch(`/v1/measure/${measure_id}/refuse`, {
        message,
        measure_id,
      })
      .then((response) => {
        toastAlert({
          type: "success",
          message: `Já é possível encontrá-la na aba de medições recusadas.`,
          title: "Medição recusada",
        });

        navigate("/measures");
        handleClose();
      })
      .catch((error) => {
        toastAlert({
          type: "error",
          message: `Verifique sua conexão e tente novamente.`,
          title: "Não foi possível recusar a medição",
        });
      })
      .finally(() => setloading(false));
  };

  return (
    <Modal
      title="Porque deseja reprovar a medição?"
      open={isModalOpen}
      onOk={handleRefuse}
      onCancel={handleCancel}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={!message || loading}
          danger
          onClick={handleRefuse}
        >
          Reprovar
        </Button>,
      ]}
    >
      <TextArea
        placeholder="Escreva o motivo"
        rows={3}
        onChange={(event) => {
          setMessage(event.target.value);
        }}
        style={{ marginTop: 8 }}
      />
    </Modal>
  );
}

export default MeasureModal;
